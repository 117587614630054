import React, { useEffect, useState } from 'react';
import {
  Button, Input, Modal, Select, Tooltip, Popover, Collapse, Checkbox, Radio, DatePicker,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { stringify } from 'query-string';
import moment from 'moment'
import Axios from 'axios';
import {
  getQuestionAction,
  addQuestionsAction,
  updateQuestionsAction,
  deleteQuestionsAction,
  addResponseAction,
  getQuestionDataMoreAction,
  getQuestionAdditionalDetailsAction,
  updateResponseAction,
  deleteResponseAction,
} from '../SavedProjectResults/logic';
import AutoSuggest from '../AutoSuggest';
import {
  getautosuggestionCancelAction,
  getautosuggestionResetAction,
  getautosuggestvalueAction,
} from '../AddProjectName/logic';
import Loader from '../../../common/components/Loader';
import LazyLoadDiv from '../../../common/components/LazyLoadDiv';
import { ListUsersAction } from '../ShareProjectModal/logic'
import { getFormattedDate, getCookie } from '../../../utils';
import { TextType } from '../AddFilters/filter';
import { getCountryDataAction } from '../GeneralInformation/logic';
import { getProjectFilterAction } from '../../components/ProjectFilters/logic';
// import { responsiveArray } from 'antd/lib/_util/responsiveObserve';

const { Option } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const CountryQuestionModal = ({
  questionModal,
  setQuestionModal,
  projectID,
  setViewQuestionScreen,
}) => {
  const [questionList, setQuestionList] = useState([]);
  const dispatch = useDispatch();
  const getQuestionResponse = useSelector((reduxState) => reduxState.getQuestionResponse || {});
  const addQuestionsResponse = useSelector((reduxState) => reduxState.addQuestionsResponse || {});
  const updateQuestionsResponse = useSelector(
    (reduxState) => reduxState.updateQuestionsResponse || {},
  );
  const deleteQuestionsResponse = useSelector(
    (reduxState) => reduxState.deleteQuestionsResponse || {},
  );
  const addResponse = useSelector((reduxState) => reduxState.addResponse || {});
  const getQuestionAdditionalDetailsResponse = useSelector(
    (reduxState) => reduxState.getQuestionAdditionalDetailsResponse || {},
  );

  const updateResponse = useSelector((reduxState) => reduxState.updateResponse || {});
  const deleteResponse = useSelector((reduxState) => reduxState.deleteResponse || {})

  const [deleteResponseConfirmationModal, setDeleteResponseConfirmationModal] = useState(false);
  // const getCountryData = useSelector((store) => store.getCountryData);
  const [innoplexusId, setInnoplexusId] = useState('');
  const [editQuestionModal, setEditQuestionModal] = useState(false);
  const [addResponseModal, setAddResponseModal] = useState(false);
  const [updateQuestionConfirmationModal, setUpdateQuestionConfirmationModal] = useState(false);
  const [deleteQuestionConfirmationModal, setDeleteQuestionConfirmationModal] = useState(false);
  const [from, setFrom] = useState(1);
  const [isFilterPopoverVisible, setIsFilterPopoverVisible] = useState(false);
  const runAnalysisFilters = useSelector((state) => state.projectFilters);

  const [selectedFilters, setSelectedFilters] = useState({
    indication_name: [],
    question_type: [],
    overall_status: [],
    country: [],
    question_asked_by: [],
    question_date: undefined,
    response_by: [],
    response_date: undefined,
  });

  const [indicationForAdd, setIndicationForAdd] = useState([])

  const [openPanels, setOpenPanels] = useState([]);
  const [addQuestionModal, setAddQuestionModal] = useState(false);

  const [filterValues, setFilterValues] = useState([]);
  const [formState, setFormState] = useState([{ type: '' }]);
  const [formStateForResponse, setFormStateForResponse] = useState([{ type: '' }]);

  const [addQuestionData, setAddQuestionData] = useState({
    question_text: '',
    question_category: '',
    country: [],
  });

  const [optionChoice, setOptionChoice] = useState('');
  const [optionValue, setOptionValue] = useState(['']);
  const [userList, setUserList] = useState([])
  // const [countryList, setCountryList] = useState([]);

  const [showDataForResponse, setShowDataForResponse] = useState({
    question_id: '',
    questionText: '',
    question_category: '',
    country: [],
    additonal_details: {},
    options: {},
    additional_response_required: {},
    combinedResponse: [],
    status: {},
  });

  const [responseOption, setResponseOption] = useState('');
  const [responseTextCapture, setResponseTextCapture] = useState([]);

  const [selectedCountryForResponse, setSelectedCountryForResponse] = useState('');
  const [comments, setComments] = useState('');

  // for edit data
  const [formStateForEdit, setFormStateForEdit] = useState([{ type: '' }]);
  const [indicationForCopy, setIndicationForCopy] = useState('')
  // const [formStateForResponseForEdit, setFormStateForResponseForEdit] = useState([{ type: '' }]);

  const [addQuestionDataForEdit, setAddQuestionDataForEdit] = useState({
    question_text: '',
    question_category: '',
    country: [],
  });

  const [optionChoiceForEdit, setOptionChoiceForEdit] = useState('');
  const [optionValueForEdit, setOptionValueForEdit] = useState(['']);

  // for copy question

  const [copyQuestion, setCopyQuestion] = useState({
    question_text: '',
    question_category: 'Generic',
    country: [],
  });

  const [formStateForCopy, setFormStateForCopy] = useState([{ type: '' }]);
  const [indicationForEdit, setIndicationForEdit] = useState('')

  const [optionChoiceForCopy, setOptionChoiceForCopy] = useState('');
  const [optionValueForCopy, setOptionValueForCopy] = useState(['']);
  const [CopyQuestionModal, setCopyQuestionModal] = useState(false)

  const [editDataForResponse, setEditShowDataForResponse] = useState({
    question_id: '',
    questionText: '',
    question_category: '',
    country: [],
    additonal_details: {},
    options: {},
    additional_response_required: {},
    combinedResponse: [],
    comments: '',
  });

  // edit response
  const [editResponseModal, setEditResponseModal] = useState(false)
  const useresListResponse = useSelector((store) => store.usersList)
  const [editResponseOption, setEditResponseOption] = useState('');

  const [selectedDatesForQuestion, setSelectedDatesForQuestion] = useState('');
  const [selectedDatesForResponse, setSelectedDatesForResponse] = useState('');

  useEffect(() => {
    dispatch(
      getQuestionAction({
        project_id: projectID,
        page: 1,
        size: 10,
        filters: JSON.stringify({ ...selectedFilters }),
      }),
    );
    dispatch(getCountryDataAction());
    dispatch(getQuestionAdditionalDetailsAction());
    dispatch(getProjectFilterAction({
      project_id: projectID,
      type: 'countries',
    }))
  }, []);

  useEffect(() => {
    dispatch(ListUsersAction())
  }, [])

  useEffect(() => {
    if (deleteResponse.flag) {
      setDeleteResponseConfirmationModal(false);
      setInnoplexusId('')
      dispatch(
        getQuestionAction({
          project_id: projectID,
          page: 1,
          size: 10,
          filters: JSON.stringify({ ...selectedFilters }),
        }),
      );
    }
  }, [JSON.stringify(deleteResponse)]);

  useEffect(() => {
    if (updateResponse.flag) {
      setEditResponseModal(false)
      setInnoplexusId('')
      dispatch(
        getQuestionAction({
          project_id: projectID,
          page: 1,
          size: 10,
          filters: JSON.stringify({ ...selectedFilters }),
        }),
      );
    }
  }, [JSON.stringify(updateResponse)])

  useEffect(() => {
    if (useresListResponse.flag) {
      setUserList(useresListResponse.data.data.users)
    }
  }, [JSON.stringify(useresListResponse)])

  // useEffect(() => {
  //   if (getCountryData.flag) {
  //     setCountryList(getCountryData?.data?.distinct_countries);
  //   }
  // }, [JSON.stringify(getCountryData)]);

  useEffect(() => {
    if (getQuestionAdditionalDetailsResponse.flag) {
      setFilterValues(getQuestionAdditionalDetailsResponse.data.data);
    }
  }, [JSON.stringify(getQuestionAdditionalDetailsResponse)]);

  useEffect(() => {
    if (getQuestionResponse.flag) {
      if (getQuestionResponse.data.data.length) {
        setQuestionList(getQuestionResponse.data.data);
      } else {
        setQuestionList([]);
      }
    }
  }, [JSON.stringify(getQuestionResponse)]);

  useEffect(() => {
    if (deleteQuestionsResponse.flag) {
      setDeleteQuestionConfirmationModal(false);
      dispatch(
        getQuestionAction({
          project_id: projectID,
          page: 1,
          size: 10,
          filters: JSON.stringify({ ...selectedFilters }),
        }),
      );
    }
  }, [JSON.stringify(deleteQuestionsResponse)]);

  useEffect(() => {
    if (addResponse.flag) {
      setAddResponseModal(false);
      setResponseOption('');
      setSelectedCountryForResponse('');
      setComments('');
      dispatch(
        getQuestionAction({
          project_id: projectID,
          page: 1,
          size: 10,
          filters: JSON.stringify({ ...selectedFilters }),
        }),
      );
    }
  }, [JSON.stringify(addResponse)]);

  useEffect(() => {
    if (addQuestionsResponse.flag) {
      setAddQuestionModal(false);
      setCopyQuestionModal(false)
      setFormState([{ type: '' }]);
      setFormStateForResponse([{ type: '' }]);
      setAddQuestionData({
        question_text: '',
        question_category: '',
        country: [],
      });
      setOptionChoice('');
      setOptionValue(['']);
      dispatch(
        getQuestionAction({
          project_id: projectID,
          page: 1,
          size: 10,
          filters: JSON.stringify({ ...selectedFilters }),
        }),
      );
    }
  }, [JSON.stringify(addQuestionsResponse)]);

  const renderLabel = (key) => {
    if (key === 'age_range') {
      return 'Age Range'
    }
    if (key === 'dosage') {
      return 'Dosage'
    }
    if (key === 'indication') {
      return 'Indication'
    }
    if (key === 'line_of_therapy') {
      return 'Line of Therapy'
    }
    if (key === 'moa') {
      return 'Mechanism of Action/Drug Class/ Regiment'
    }
    if (key === 'roa') {
      return 'Route of Administration'
    }
    if (key === 'drug') {
      return 'Drug'
    }
    return ''
  }

  useEffect(() => {
    if (updateQuestionsResponse.flag) {
      setUpdateQuestionConfirmationModal(false);
      setEditQuestionModal(false);
      setInnoplexusId('');
      dispatch(
        getQuestionAction({
          project_id: projectID,
          page: 1,
          size: 10,
          filters: JSON.stringify({ ...selectedFilters }),
        }),
      );
    }
  }, [JSON.stringify(updateQuestionsResponse)]);

  const onIndicationChangeInput = (e) => {
    if (e?.trim() && e.length > 2) {
      dispatch(
        getautosuggestvalueAction({
          query: e.trim(),
          type: 'indications',
        }),
      );
    }
  };

  // const onIndicationChangeInput = (e) => {
  //   if (e.trim() && e.length > 2) {
  //     dispatch(
  //       getautosuggestvalueAction({
  //         query: e.trim(),
  //         type: 'indications',
  //         dataset: 'queries',
  //       }),
  //     )
  //   }
  // }

  // const columns = [
  //   {
  //     title: 'Question Text',
  //     dataIndex: 'question-text',
  //   },
  //   {
  //     title: 'Details',
  //     dataIndex: 'additional-details',
  //   },
  //   {
  //     title: 'Question Category',
  //     dataIndex: 'question-category',
  //   },
  //   {
  //     title: 'Country',
  //     dataIndex: 'country',
  //   },
  //   {
  //     title: 'Response',
  //     dataIndex: 'response',
  //   },
  //   {
  //     title: 'Status',
  //     dataIndex: 'status',
  //   },
  //   {
  //     title: 'Action',
  //     dataIndex: 'add-response',
  //   },
  // ];

  // const renderHeader = () => {
  //   return (
  //     <div className="table-row table-header">
  //       {columns.map((colItm, index) => (
  //         <div
  //           className={colItm.title === 'Action' ? 'table-row-item add-respo' : `table-row-item ${colItm.dataIndex}`}
  //           key={index}
  //         >
  //           {colItm.title}
  //         </div>
  //       ))}
  //     </div>
  //   );
  // };

  const onUpdateResponse = () => {
    const status = {
      [selectedCountryForResponse]: 'Answered',
    };

    // const data1 = Object.keys(responseTextCapture).map((key) => {
    //   return responseTextCapture[key];
    // });

    const body = {
      response_text:
        editDataForResponse.options.type === 'Multiple Choice'
          ? editResponseOption.join(',')
          : editResponseOption,
      status,
      country: selectedCountryForResponse,
      comments: editDataForResponse.comments,
      // additional_response_required: data1,
    };

    console.log('editDataForResponse', editDataForResponse)

    // console.log('editDataForResponse', data)
    dispatch(
      updateResponseAction({
        params: {
          innoplexus_id: innoplexusId,
        },
        body,
      }),
    );
  }

  const renderHeaderForCollapse = (tableItm) => {
    // const firstKey = Object.keys(tableItm.status)[0];
    // const firstValue = Object.values(tableItm.status)[0];

    // const firstKey1 = Object.keys(tableItm.additonal_details)[0];
    // const firstValue1 = Object.values(tableItm.additonal_details)[0];
    return (
      <div className="table-row table-body project-specific panel-header-info">
        <Tooltip title={tableItm.question_text}>
          <div className="table-row-item question-text"> {tableItm.question_text}</div>
        </Tooltip>
        {/* <div className="table-row-item additional-details">
          {
            Object.keys(tableItm.additonal_details).length ? (
              <div className="additional-details-in">
                <div className="additional-details-in-con">
                  <div className="additional-details-key">{renderLabel(firstKey1)} : </div>
                  <div className="additional-details-val">{firstValue1} </div>
                </div>
              </div>
            ) : '-'
          }
        </div> */}
        {/* <div className="table-row-item question-category"> {tableItm.question_category}</div>
        <div className="table-row-item country"> {tableItm.country.join(', ')}</div>
        <div className="table-row-item response" style={{ display: 'block' }}>
          {
            tableItm.responses.length ? (
              <>
                <div className="table-row-item-inside question-response">
                  <div className="question-response-content">
                    <div className="date-ques"> { tableItm.responses[0].country}</div>
                    <div className="date-ques">
                      {getFormattedDate(tableItm.responses[0].created_on * 1000)}
                    </div>
                    <div className="main-resp-con">
                      <div className="main-resp"> Main Response:</div>
                    </div>
                  </div>
                </div>
              </>

            ) : (
              <div className="table-row-item-inside question-response">-</div>
            )
          }
        </div>
        <div className="table-row-item status" style={{ display: 'block' }}>
          <div className="status-content">
            <div className="status-key">{firstKey} </div>
            <div className="status-value">{firstValue} </div>
          </div>
        </div> */}
      </div>
    );
  };

  const onChange = (key) => {
    setOpenPanels(key);
  };

  const onAddResponseClick = (data) => {
    let values = []

    if (data.additional_response_required.length === 1) {
      const names = data.additional_response_required.map((user) => {
        return user.key;
      });
      if (names[0] === '') {
        values = []
      } else {
        data.additional_response_required.forEach((object) => {
          object.value = '';
        });
        values = data.additional_response_required
      }
    } else {
      data.additional_response_required.forEach((object) => {
        object.value = '';
      });
      values = data.additional_response_required
    }

    const updatedData1 = values.map((item) => ({
      ...item, // Spread operator to keep existing properties
      detailsValue: data.additonal_details[item.key] || '', // Assigning value from data or empty string if not found
    }));

    // combinedResponse
    setShowDataForResponse({
      question_id: data.id,
      question_text: data.question_text,
      question_category: data.question_category,
      country: data.country,
      additonal_details: data.additonal_details,
      options: data.options,
      additional_response_required: values,
      combinedResponse: updatedData1,
      status: data.status,
    });
    setResponseTextCapture(values)
    setAddResponseModal(true);
  };

  const renderQuestionBody = (tableItm) => {
    return (
      <div className="question-main-content">
        <div className="question-content-body">
          <div className="question-details-page">
            <div className="question-details-header">Details</div>
            <div
              className="question-btn"
            >
              <Button
                title="Copy Question"
                className="copy-icon"
                  // disabled={(tableItm.status === 'Archived') || !(getCookie('permissions').includes('super_admin') || getCookie('permissions').includes('lcor_access') || getCookie('permissions').includes('sfl_access') || getCookie('permissions').includes('cfl_access'))}
                onClick={() => {
                  onCopyQuestion(tableItm);
                }}
              />
              <Button
                title="Add Response"
                className="add-icon"
                disabled={(tableItm.status === 'Archived') || Object.values(tableItm.status).every((status) => status === 'Answered') || !(getCookie('permissions').includes('super_admin') || getCookie('permissions').includes('lcor_access') || getCookie('permissions').includes('sfl_access') || getCookie('permissions').includes('cfl_access'))}
                onClick={() => {
                  onAddResponseClick(tableItm);
                }}
              />
              <Button
                onClick={() => onEditQuestion(tableItm)}
                title="Edit Question"
                className="edit-icon"
                disabled={(Object.values(tableItm.status).includes('Answered')) || !(getCookie('permissions').includes('super_admin') || getCookie('permissions').includes('lcor_access') || getCookie('permissions').includes('sfl_access') || getCookie('permissions').includes('cfl_access'))}
              />
              <Button
                title="Delete Question"
                className="del-icon"
                disabled={(Object.values(tableItm.status).includes('Answered')) || !(getCookie('permissions').includes('super_admin') || getCookie('permissions').includes('lcor_access') || getCookie('permissions').includes('sfl_access') || getCookie('permissions').includes('cfl_access'))}
                onClick={() => {
                  setInnoplexusId(tableItm.innoplexus_id);
                  setDeleteQuestionConfirmationModal(true);
                }}
              />
            </div>
          </div>
          <div className="table-row-item-content details-section" style={{ display: 'flex' }}>
            <div className="table-row-item-content question-category-content">
              <div className="table-row-item-content question-category-title" style={{ paddingBottom: '5px' }}>Question Category</div>
              <div className="table-row-item-content question-category-value">
                {tableItm?.question_category?.length === '' ? '-' : tableItm?.question_category}
              </div>
            </div>
            <div className="table-row-item-content created-by-content">
              <div className="table-row-item-content created-by-title" style={{ paddingBottom: '5px' }}>Question Asked By</div>
              <div className="table-row-item-content created-by-value">{tableItm?.user_name === '' ? '-' : tableItm?.user_name}</div>
            </div>
            <div className="table-row-item-content created-date-content">
              <div className="table-row-item-content created-date-title" style={{ paddingBottom: '5px' }}>Question Creation Date</div>
              <div className="table-row-item-content created-date-title">{getFormattedDate(tableItm?.created_on * 1000)}</div>
            </div>
            <div className="table-row-item-content age-range-content">
              <div className="table-row-item-content age-range-title" style={{ paddingBottom: '5px' }}>Age Range</div>
              <div className="table-row-item-content age-range-value">
                {tableItm?.additonal_details?.age_range === undefined || tableItm?.additonal_details?.age_range.length === ''
                  ? '-'
                  : tableItm?.additonal_details?.age_range}
              </div>
            </div>

            <div className="table-row-item-content indication-content">
              <div className="table-row-item-content indication-content-title" style={{ paddingBottom: '5px' }}>Indication</div>
              <div className="table-row-item-content indication-content-value">
                {tableItm?.additonal_details?.indication === undefined || tableItm?.additonal_details?.indication?.length === ''
                  ? '-'
                  : tableItm?.additonal_details?.indication}
              </div>
            </div>

            <div className="table-row-item-content drug-content">
              <div className="table-row-item-content drug-content-title" style={{ paddingBottom: '5px' }}>Drug</div>
              <div className="table-row-item-content drug-content-value">
                {tableItm?.additonal_details?.drug === undefined || tableItm?.additonal_details?.drug?.length === ''
                  ? '-'
                  : tableItm?.additonal_details?.drug}
              </div>
            </div>
          </div>

          <div className="table-row-item-content  details-section" style={{ display: 'flex', paddingTop: '25px' }}>
            <div className="table-row-item-content country-content">
              <div className="table-row-item-content country-content-title" style={{ paddingBottom: '5px' }}> Country</div>
              <div className="table-row-item-content country-content-value">{tableItm?.country?.join(', ')}</div>
            </div>
            <div className="table-row-item-content dosage-content">
              <div className="table-row-item-content dosage-content-title" style={{ paddingBottom: '5px' }}>Dosage</div>
              <div className="table-row-item-content dosage-content-value">
                {tableItm?.additonal_details?.dosage === undefined || tableItm?.additonal_details?.dosage?.length === ''
                  ? '-'
                  : tableItm?.additonal_details?.dosage}
              </div>
            </div>
            <div className="table-row-item-content lot-content">
              <div className="table-row-item-content lot-content-title" style={{ paddingBottom: '5px' }}> Line of Therapy</div>
              <div className="table-row-item-content lot-content-value">
                {tableItm?.additonal_details?.line_of_therapy === undefined || tableItm?.additonal_details?.line_of_therapy?.length === ''
                  ? '-'
                  : tableItm?.additonal_details?.line_of_therapy}
              </div>
            </div>
            <div className="table-row-item-content moa-content">
              <div className="table-row-item-content moa-content-title" style={{ paddingBottom: '5px' }}> Mechanism of Action/Drug Class/ Regiment</div>
              <div className="table-row-item-content moa-content-value">
                {tableItm?.additonal_details?.moa === undefined || tableItm?.additonal_details?.moa?.length === ''
                  ? '-'
                  : tableItm?.additonal_details?.moa}
              </div>
            </div>
            <div className="table-row-item-content roa-content">
              <div className="table-row-item-content roa-content-title" style={{ paddingBottom: '5px' }}>Route of Administration</div>
              <div className="table-row-item-content roa-content-value">
                {tableItm?.additonal_details?.roa === undefined || tableItm?.additonal_details?.roa?.length === ''
                  ? '-'
                  : tableItm?.additonal_details?.roa}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onClickEditResponse = (data, reponseArray) => {
    console.log('data', data)
    console.log('data1', reponseArray)

    setInnoplexusId(reponseArray.innoplexus_id)

    // const filterResponse = reponseArray.filter((itm) => itm.country === country)
    // if(filterResponse)
    setEditShowDataForResponse({
      question_id: data.id,
      question_text: data.question_text,
      question_category: data.question_category,
      country: data.country,
      additonal_details: data.additonal_details,
      options: data.options,
      comments: reponseArray.comments,
    });

    // reponseArray[0].response_text
    if (data.options.type === 'Multiple Choice') {
      setEditResponseOption(reponseArray.response_text.split(','))
    } else {
      setEditResponseOption(reponseArray.response_text)
    }
    setEditResponseModal(true);
    setSelectedCountryForResponse(reponseArray.country)
    // console.log('heyyy', obj, responseData)
  }

  const onDeleteResponseCall = () => {
    dispatch(
      deleteResponseAction({
        innoplexus_id: innoplexusId,
      }),
    );
  };

  const renderResponseBody = (tableItem) => {
    console.log('tableItem-------------->', tableItem)
    // console.log('Data-------------->', data)
    // const extractedCountryResponses = data?.filter((response) => response.country === selectedCountry);
    // console.log('extractedCountryResponses', extractedCountryResponses)
    // return (
    //   <div>
    //     {data.length > 0 ? (
    //       data.map((itm) => {
    //         return (
    //           <div
    //             key={itm.id} // Ensure to add a unique key for each item
    //             className="response-main-content"
    //             style={{
    //               backgroundColor: '#f4f5fa',
    //               padding: '10px',
    //               borderRadius: '4px',
    //               width: '100%',
    //             }}
    //           >
    //             <div className="response-main-body" style={{ display: 'flex' }}>
    //               <div className="response-section" style={{ display: 'flex' }}>
    //                 <div className="response-main-content-title">
    //                   Response
    //                 </div>
    //                 {console.log('tableItem.status', tableItem.status)}
    //                 {Object.keys(tableItem.status).map((key) => (
    //                   <div className="status-content">
    //                     {/* <div className="status-key">{key}: </div> */}
    //                     <div className="status-value">{tableItem.status[key]} </div>
    //                   </div>
    //                 ))}
    //                 {/* <div className="response-answered-text">{tableItem.status[]}</div> */}
    //               </div>
    //               <div className="response-main-content response-btn" style={{ display: 'flex', position: 'absolute', right: '30px' }}>
    //                 <div className="response-main-content response-btn" style={{ paddingRight: '10px' }} role="presentation" onClick={() => onClickEditResponse(tableItem, data)}>edit</div>
    //                 <div className="response-main-content response-btn">delete</div>
    //               </div>
    //             </div>
    //             <div className="table-row-item-content details-section-response" style={{ display: 'flex' }}>
    //               {Object.keys(tableItem.status).map((key) => (
    //                 // <div className="status-content">
    //                 //   {/* <div className="status-key">{key}: </div> */}
    //                 //   <div className="status-value">{tableItem.status[key]} </div>
    //                 // </div>
    //                 // console.log('checking key', key)
    //                 <div className="table-row-item-content response-by-content">
    //                   <div className="table-row-item-content response-by-content-title">Country</div>
    //                   {console.log('checking key', key)}
    //                   <div className="table-row-item-content response-by-content-value">{key}</div>
    //                 </div>
    //               ))}
    //               <div className="table-row-item-content response-by-content">
    //                 <div className="table-row-item-content response-by-content-title">Response by</div>
    //                 <div className="table-row-item-content response-by-content-value">Shradha</div>
    //               </div>

    //               <div className="table-row-item-content response-date-content">
    //                 <div className="table-row-item-content response-date-content-title">Response Time</div>
    //                 <div className="table-row-item-content response-date-content-value">{getFormattedDate(itm.created_on * 1000)}</div>
    //               </div>

    //               <div className="table-row-item-content main-response-content">
    //                 <div className="table-row-item-content main-response-content-title">Main Response</div>
    //                 <div className="table-row-item-content main-response-content-value">{itm.response_text}</div>
    //               </div>

    //               <div className="table-row-item-content comment-content">
    //                 <div className="table-row-item-content comment-content-title">Comment</div>
    //                 <div className="table-row-item-content comment-content-value">{itm.comments}</div>
    //               </div>
    //             </div>
    //           </div>
    //         );
    //       })
    //     ) : (
    //       <p>No responses yet.</p> // Message when there are no responses
    //     )}
    //   </div>
    // )

    return (
      <div>
        {tableItem.country.map((c) => (
          <div className="response-main-content">
            {tableItem.status[c] === 'Answered' ? (
            // Show responses for the current country
              tableItem.responses.filter((response) => response.country === c).length > 0 ? (
                tableItem.responses.filter((response) => response.country === c).map((response) => (
                  <>
                    <div className="response-main-body" key={c}>
                      <div className="response-section">
                        <div className="response-main-content-title">
                          Response
                        </div>
                        <div className="status-content">
                          {/* <div className="status-key">{key}: </div> */}
                          {/* <div className="status-value">{tableItem.status[c]} </div> */}
                          <div className={tableItem.status[c] === 'Answered' ? 'response-answered-text answered' : 'response-answered-text not-answered'}>{tableItem.status[c]}</div>
                        </div>
                      </div>
                      <div className="response-btn">
                        <Button
                          onClick={() => onClickEditResponse(tableItem, response)}
                          title="Edit Response"
                          className="edit-icon"
                        />
                        <Button
                          title="Delete Response"
                          className="del-icon"
                          onClick={() => {
                            setInnoplexusId(response.innoplexus_id);
                            setDeleteResponseConfirmationModal(true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="table-row-item-content details-section-response details-section-response-more" style={{ display: 'flex' }}>
                      <div className="table-row-item-content response-by-content">
                        <div className="table-row-item-content response-by-content-title">Country</div>
                        {/* {console.log('checking key', key)} */}
                        <div className="table-row-item-content response-by-content-value">{c}</div>
                      </div>
                      <div className="table-row-item-content response-by-content">
                        <div className="table-row-item-content response-by-content-title">Response by</div>
                        <div className="table-row-item-content response-by-content-value">{response.name === '' ? '-' : response.name}</div>
                      </div>

                      <div className="table-row-item-content response-date-content">
                        <div className="table-row-item-content response-date-content-title">Response Date</div>
                        <div className="table-row-item-content response-date-content-value">{getFormattedDate(response.created_on * 1000)}</div>
                      </div>

                      <div className="table-row-item-content main-response-content">
                        <div className="table-row-item-content main-response-content-title">Main Response</div>
                        <div className="table-row-item-content main-response-content-value">{response.response_text}</div>
                      </div>

                      <div className="table-row-item-content comment-content">
                        <div className="table-row-item-content comment-content-title">Comment</div>
                        <div className="table-row-item-content comment-content-value">{response.comments}</div>
                      </div>

                    </div>
                  </>
                ))
              ) : (
                <p>No responses available for {c}.</p>
              )
            ) : (
            // Show placeholder values for Not Answered status
              <>
                <div className="response-main-body" key={c}>
                  <div className="response-section">
                    <div className="response-main-content-title">
                      Response
                    </div>
                    <div className="status-content">
                      {/* <div className="status-key">{key}: </div> */}
                      {/* <div className="status-value">{tableItem.status[c]} </div> */}
                      <div className={tableItem.status[c] === 'Answered' ? 'response-answered-text answered' : 'response-answered-text not-answered'}>{tableItem.status[c]}</div>
                    </div>
                  </div>
                  <div className="response-btn">
                    <Button
                      disabled
                      // onClick={() => onClickEditResponse(tableItem, response)}
                      title="Edit Response"
                      className="edit-icon"
                    />
                    <Button
                      title="Delete Response"
                      className="del-icon"
                      disabled
                      // onClick={() => {
                      //   setInnoplexusId(response.innoplexus_id);
                      //   setDeleteResponseConfirmationModal(true);
                      // }}
                    />
                  </div>
                </div>
                <div className="table-row-item-content details-section-response details-section-response-more" style={{ display: 'flex' }}>
                  <div className="table-row-item-content response-by-content">
                    <div className="table-row-item-content response-by-content-title">Country</div>
                    {/* {console.log('checking key', key)} */}
                    <div className="table-row-item-content response-by-content-value">{c}</div>
                  </div>
                  <div className="table-row-item-content response-by-content">
                    <div className="table-row-item-content response-by-content-title">Response by</div>
                    <div className="table-row-item-content response-by-content-value">-</div>
                  </div>

                  <div className="table-row-item-content response-date-content">
                    <div className="table-row-item-content response-date-content-title">Response Date</div>
                    <div className="table-row-item-content response-date-content-value">-</div>
                  </div>

                  <div className="table-row-item-content main-response-content">
                    <div className="table-row-item-content main-response-content-title">Main Response</div>
                    <div className="table-row-item-content main-response-content-value">-</div>
                  </div>

                  <div className="table-row-item-content comment-content">
                    <div className="table-row-item-content comment-content-title">Comment</div>
                    <div className="table-row-item-content comment-content-value">-</div>
                  </div>

                  {/* <div className="response-main-content response-btn">
                    <div className="response-main-content response-btn" style={{ paddingRight: '10px' }} role="presentation">edit</div>
                    <div className="response-main-content response-btn">delete</div>
                  </div> */}
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    )
  };

  const renderListOfQuestion = () => {
    return questionList.map((tableItm, index) => (
      <Collapse activeKey={openPanels} onChange={onChange}>
        <Panel
          // header={
          //   !openPanels.includes(index.toString()) || openPanels[0] === undefined
          //     ? renderHeaderForCollapse(tableItm)
          //     : ''
          // }
          header={renderHeaderForCollapse(tableItm)}
          key={index}
        >
          <div className="table-row table-body">
            <div className="table-row-content table-body-content">
              <div className="table-row-content table-body-content question-content">{renderQuestionBody(tableItm)}</div>
              <div className="table-row-content table-body-content response-content">{renderResponseBody(tableItm)}</div>
            </div>
            {/* <div
              className={
                tableItm.childs.length
                  ? 'table-row table-body parent-question-body'
                  : 'table-row table-body no-parent-question-body'
              }
            >
              <div className="table-row table-body" key={index}>
                <div className="table-row-item question-text">{tableItm.question_text}</div>
                <div className="table-row-item additional-details">
                  <div className="additional-details-in">
                    {Object.keys(tableItm.additonal_details).length ? (Object.keys(tableItm.additonal_details).map((key) => (
                      <div className="additional-details-in-con">
                        <div className="additional-details-key">{renderLabel(key)} : </div>
                        <div className="additional-details-val">{tableItm.additonal_details[key]} </div>
                      </div>
                    ))) : '-'}
                  </div>
                </div>
                <div className="table-row-item question-category"> {tableItm.question_category}</div>
                <div className="table-row-item country"> {tableItm.country.join(', ')}</div>
                <div className="table-row-item response" style={{ display: 'block' }}>
                  {tableItm.responses.length ? (
                    tableItm.responses.map((itm) => {
                      return (
                        <>
                          <div className="table-row-item-inside question-response">
                            <div className="question-response-content">
                              <div className="country-date">
                                <div className="date-ques"> {itm.country}</div>
                                <div className="date-ques">
                                  {getFormattedDate(itm.created_on * 1000)}
                                </div>
                              </div>
                              <div className="date-ques">
                                {itm.name}
                              </div>
                              <div className="main-resp-con">
                                <div className="main-resp"> Main Response:</div>
                                <div className="text-ques">
                                  {itm.response_text}
                                </div>
                              </div>
                              <div className="additonal-res-required">
                                <div className="additonal-res-required-title"> Details: </div>
                                <div className="additonal-res-required-content">
                                  {itm.additional_response_required.length ? (
                                    itm.additional_response_required.map((data) => {
                                      return (
                                        <div className="additonal-res-required-body">
                                          <div className="additonal-res-required-key">{renderLabel(data.key)}:  </div>
                                          <div className="additonal-res-required-value">{data.value ? data.value : '-'} </div>
                                        </div>
                                      );
                                    })) : '-'}
                                </div>
                              </div>
                            </div>
                            <div className="comments-sec-content">
                              <div className="comments-sec-title"> Comments: </div>
                              <div className="comments-sec-value"> {itm.comments ? itm.comments : '-'}</div>
                            </div>
                          </div>
                          <br />
                        </>
                      );
                    })
                  ) : (
                    <div className="table-row-item-inside question-response">-</div>
                  )}
                </div>
                <div className="table-row-item status" style={{ display: 'block' }}>
                  {' '}
                  {Object.keys(tableItm.status).map((key) => (
                    <div className="status-content">
                      <div className="status-key">{key}: </div>
                      <div className="status-value">{tableItm.status[key]} </div>
                    </div>
                  ))}
                </div>
                <div className="table-row-item add-respo">
                  <Button
                    title="Copy Question"
                    className="copy-icon"
                    // disabled={(tableItm.status === 'Archived') || !(getCookie('permissions').includes('super_admin') || getCookie('permissions').includes('lcor_access') || getCookie('permissions').includes('sfl_access') || getCookie('permissions').includes('cfl_access'))}
                    onClick={() => {
                      onCopyQuestion(tableItm);
                    }}
                  />
                  <Button
                    title="Add Response"
                    className="add-icon"
                    disabled={tableItm.status === 'Archived'}
                    onClick={() => {
                      onAddResponseClick(tableItm);
                    }}
                  />
                  <Button
                    onClick={() => onEditQuestion(tableItm)}
                    title="Edit Question"
                    className="edit-icon"
                    disabled={Object.values(tableItm.status).includes('Answered')}
                  />
                  <Button
                    title="Delete Question"
                    className="del-icon"
                    disabled={Object.values(tableItm.status).includes('Answered')}
                    onClick={() => {
                      setInnoplexusId(tableItm.innoplexus_id);
                      setDeleteQuestionConfirmationModal(true);
                    }}
                  />
                </div>
              </div>
            </div> */}
          </div>
        </Panel>
      </Collapse>
    ));
  };

  const onCopyQuestion = (tableItm) => {
    setCopyQuestion({
      question_text: tableItm.question_text,
      question_category: tableItm.question_category,
      country: tableItm.country,
    });
    const additionalDetails = Object.keys(tableItm.additonal_details).map((key) => {
      return {
        type: key,
        data: tableItm.additonal_details[key],
      };
    });

    const additionalDetailsResponse = tableItm.additional_response_required.map((item) => {
      return {
        type: item.key,
        mandatory: item.mandatory,
      };
    });
    const mappingDataForEdit = additionalDetailsResponse.map((item) => {
      const found = additionalDetails.find((d) => d.type === item.type);
      return found ? { ...item, data: found.data } : item;
    });

    const indicationItem = additionalDetails.find((item) => item.type === 'indication');
    setIndicationForCopy(indicationItem ? indicationItem.data : null)

    if (mappingDataForEdit.length) {
      setFormStateForCopy(mappingDataForEdit)
    } else {
      setFormStateForCopy([''])
    }
    setOptionChoiceForCopy(tableItm.options.type);
    if (tableItm.options.choices.length) {
      setOptionValueForCopy(tableItm.options.choices);
    } else {
      setOptionValueForCopy(['']);
    }
    setCopyQuestionModal(true);
  }

  const addCopyQuestion = () => {
    const additionalDetails = formStateForCopy.reduce(
      (obj, item) => Object.assign(obj, { [item.type]: item.data }),
      {},
    );

    const dataForAdditionalResp = formStateForCopy.map((item) => {
      return {
        key: item.type,
        mandatory: item.mandatory,
        value: '',
      };
    });

    const status = copyQuestion.country.reduce(
      (obj, item) => Object.assign(obj, { [item]: 'Not Answered' }),
      {},
    );
    const body = {
      parent_id: '',
      project_id: projectID,
      question_text: copyQuestion.question_text,
      question_category: copyQuestion.question_category,
      status,
      country: copyQuestion.country,
      additonal_details: additionalDetails,
      options: {
        type: optionChoiceForCopy,
        choices:
        optionChoiceForCopy === 'Multiple Choice' || optionChoiceForCopy === 'Single Choice' ? optionValueForCopy.filter(Boolean) : [],
      },
      additional_response_required: dataForAdditionalResp,
    };

    dispatch(
      addQuestionsAction({
        body,
      },
      {
        status_flag: 'copy',
      }),
    );
  }

  // const onChangeRadioBtnForCopy = (e, index) => {
  //   setFormStateForCopy((s) => {
  //     return [...s.slice(0, index), { ...s[index], mandatory: e.target.value }, ...s.slice(index + 1)];
  //   });
  // };

  const onOptionChangeForCopy = (e, idx) => {
    const list = [...optionValueForEdit];
    list[idx] = e.target.value;
    setOptionValueForCopy(list);
  };

  const removeDefaultOptionForCopy = (idx) => {
    const list = [...optionValueForCopy];
    list.splice(idx, 1);
    if (!list.length) {
      setOptionValueForCopy(['']);
    } else {
      setOptionValueForCopy(list);
    }
  };

  const addNewOptionForCopy = () => {
    setOptionValueForCopy([...optionValueForEdit, '']);
  };

  const handleRemoveForCopy = (idx) => {
    if (formStateForCopy.length > 1) {
      setFormStateForCopy((s) => {
        const oldState = [...s];
        oldState.splice(idx, 1);
        return [...oldState];
      });
    } else {
      setFormStateForCopy([{ type: '' }])
    }
  };

  const handleDropdownForCopy = (idx, value) => {
    setFormStateForCopy((s) => {
      return [...s.slice(0, idx), { type: value }, ...s.slice(idx + 1)];
    });
  };

  const getDataForCopy = (f, idx) => {
    switch (f.type) {
      case 'dosage':
      case 'moa':
      case 'roa':
      case 'line_of_therapy':
      case 'age_range':
      case 'drug':
        return (
          <div className="render-details">
            <TextType
              type={f.type}
              state={f}
              setState={setFormStateForCopy}
              index={idx}
              fdata={filterValues}
              flag="LCOR-Question"
            />
            {/* <div className="render-radio-btn">
              <Radio.Group
                onChange={(e) => onChangeRadioBtnForCopy(e, idx)}
                value={f.mandatory === 'yes' || f.mandatory === 'Yes' ? 'Yes' : f.mandatory === 'No' || f.mandatory === 'no' ? 'No' : undefined}
              >
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </div> */}
          </div>
        );

      case 'indication':
        return (
          <div className="render-details">
            <AutoSuggest
              apiCall={onIndicationChangeInput}
              apiName="autosuggestdata"
              onChange={(val) => handleChangeForCopy(val)}
              selections={indicationForCopy}
              setValue={(val) => handleChangeForCopy(val)}
              cancelAction={getautosuggestionCancelAction}
              resetAction={getautosuggestionResetAction}
              key="indication"
              defaultValue={indicationForCopy}
            />
            {/* <div className="render-radio-btn">
              <Radio.Group
                onChange={(e) => onChangeRadioBtnForCopy(e, idx)}
                value={f.mandatory === 'yes' || f.mandatory === 'Yes' ? 'Yes' : f.mandatory === 'No' || f.mandatory === 'no' ? 'No' : undefined}
              >
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </div> */}
          </div>

        )

      default:
        return <></>;
    }
  };

  const dropDataForCopy = (f, idx) => {
    return (
      <>
        <Select
          value={f.type}
          className="select-drodown-item"
          onChange={(e) => handleDropdownForCopy(idx, e)}
          style={{ minWidth: '40%' }}
        >
          {filterValues
            && filterValues.map((l, index) => {
              return (
                <Option
                  key={index}
                  value={l.key}
                  // disabled={isDropDownDisabled(l.key, 'editQuestion')}
                >
                  {l.value}
                </Option>
              );
            })}
        </Select>

        <div>{getDataForCopy(f, idx)}</div>
      </>
    );
  };

  const renderAdditionalDetailsForCopy = () => {
    return (
      <div className="filter-wrapper">
        {/* <div className="mandatory-text">Is Mandatory?</div> */}
        {formStateForCopy
          && formStateForCopy.map((f, idx) => {
            return (
              <div key={idx} className="newfilter">
                <div
                  className="filter-sec"
                  style={{
                    display: 'flex',
                    margin: 10,
                  }}
                >
                  {dropDataForCopy(f, idx)}
                  <>
                    {formStateForCopy.length > 0 && (
                      <Button
                        onClick={() => handleRemoveForCopy(idx)}
                        className="plus-btn minus-btn"
                      >
                        -
                      </Button>
                    )}
                    {idx === formStateForCopy.length - 1 && (
                      <Button onClick={handleAddForCopy} className="plus-btn">
                        +
                      </Button>
                    )}
                  </>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const onEditQuestion = (tableItm) => {
    setInnoplexusId(tableItm.innoplexus_id);
    setAddQuestionDataForEdit({
      question_text: tableItm.question_text,
      question_category: tableItm.question_category,
      country: tableItm.country,
    });
    const additionalDetails = Object.keys(tableItm.additonal_details).map((key) => {
      return {
        type: key,
        data: tableItm.additonal_details[key],
      };
    });

    if (additionalDetails.length) {
      setFormStateForEdit(additionalDetails);
    } else {
      setFormStateForEdit([''])
    }

    const indicationItem = additionalDetails.find((item) => item.type === 'indication');
    setIndicationForEdit(indicationItem ? indicationItem.data : null)
    // if (data.length) {
    //   setFormStateForEdit(data);
    // } else {
    //   setFormStateForEdit([''])
    // }

    // const additionalDetailsResponse = tableItm.additional_response_required.map((item) => {
    //   return {
    //     type: item.key,
    //     mandatory: item.mandatory,
    //   };
    // });
    // if (data1.length) {
    //   setFormStateForResponseForEdit(data1)
    // } else {
    //   setFormStateForResponseForEdit([{ type: '' }])
    // }

    // const mappingDataForEdit = additionalDetailsResponse.map((item) => {
    //   const found = additionalDetails.find((d) => d.type === item.type);
    //   return found ? { ...item, data: found.data } : item;
    // });

    // if (mappingDataForEdit.length) {
    //   setFormStateForEdit(mappingDataForEdit)
    // } else {
    //   setFormStateForEdit([''])
    // }

    setOptionChoiceForEdit(tableItm.options.type);
    if (tableItm.options.choices.length) {
      setOptionValueForEdit(tableItm.options.choices);
    } else {
      setOptionValueForEdit(['']);
    }
    setEditQuestionModal(true);
  };

  const onAddApi = () => {
    const additionalDetails = formState.reduce(
      (obj, item) => Object.assign(obj, { [item.type]: item.data }),
      {},
    );

    const dataForAdditionalResp = formStateForResponse.map((item) => {
      return {
        key: item.type,
        mandatory: item.data,
      };
    });

    const status = addQuestionData.country.reduce(
      (obj, item) => Object.assign(obj, { [item]: 'Not Answered' }),
      {},
    );

    let data12 = []

    if (dataForAdditionalResp.length) {
      const a = dataForAdditionalResp.map((value) => value.key === '');
      if (a[0] === true) {
        data12 = []
      } else {
        data12 = dataForAdditionalResp
      }
    }

    const body = {
      project_id: projectID,
      parent_id: '',
      question_text: addQuestionData.question_text,
      question_category: addQuestionData.question_category,
      status,
      country: addQuestionData.country,
      additonal_details: additionalDetails,
      options: {
        type: optionChoice,
        choices:
          optionChoice === 'Multiple Choice' || optionChoice === 'Single Choice' ? optionValue.filter(Boolean) : [],
      },
      additional_response_required: data12,
    };

    dispatch(
      addQuestionsAction({
        body,
      },
      {
        status_flag: 'add',
      }),
    );
  };

  const onDeleteApiCall = () => {
    dispatch(
      deleteQuestionsAction({
        innoplexus_id: innoplexusId,
      }),
    );
  };

  const onUpdateApiCall = () => {
    const additionalDetails = formStateForEdit.reduce(
      (obj, item) => Object.assign(obj, { [item.type]: item.data }),
      {},
    );

    const dataForAdditionalResp = formStateForEdit.map((item) => {
      return {
        key: item.type,
        mandatory: item.mandatory,
        value: '',
      };
    });

    const status = addQuestionDataForEdit.country.reduce(
      (obj, item) => Object.assign(obj, { [item]: 'Not Answered' }),
      {},
    );
    const body = {
      project_id: projectID,
      parent_id: '',
      question_text: addQuestionDataForEdit.question_text,
      question_category: addQuestionDataForEdit.question_category,
      status,
      country: addQuestionDataForEdit.country,
      additonal_details: additionalDetails,
      options: {
        type: optionChoiceForEdit,
        choices: optionValueForEdit.filter(Boolean),
      },
      additional_response_required: dataForAdditionalResp,
    };

    dispatch(
      updateQuestionsAction({
        params: {
          innoplexus_id: innoplexusId,
        },
        body,
      }),
    );
  };

  const onAddResponseApi = () => {
    const status = {
      [selectedCountryForResponse]: 'Answered',
    };

    const data1 = Object.keys(responseTextCapture).map((key) => {
      return responseTextCapture[key];
    });

    const data = {
      response_text:
        showDataForResponse.options.type === 'Multiple Choice'
          ? responseOption.join(',')
          : responseOption,
      status,
      country: selectedCountryForResponse,
      comments,
      additional_response_required: data1,
    };
    dispatch(
      addResponseAction({
        param: {
          question_id: showDataForResponse.question_id,
        },
        body: {
          data,
        },
      }),
    );
  };

  const loadMoreRowsForList = () => {
    dispatch(
      getQuestionDataMoreAction({
        project_id: projectID,
        page: from + 1,
        size: 10,
        filters: JSON.stringify({ ...selectedFilters }),
      }),
    );
    setFrom(from + 1);
  };

  const handleChange = (value, key) => {
    const tempFilters = { ...selectedFilters };
    tempFilters[key] = [value];
    setSelectedFilters(tempFilters);
  };

  const handleChangeForAdd = (value) => {
    setFormState((prevState) => prevState.map((item) => {
      if (item.type === 'indication') {
        return { ...item, data: value };
      }
      return item;
    }));
  }

  const handleChangeForEdit = (value) => {
    setFormStateForEdit((prevState) => prevState.map((item) => {
      if (item.type === 'indication') {
        return { ...item, data: value };
      }
      return item;
    }));
  }

  const handleChangeForCopy = (value) => {
    setFormStateForCopy((prevState) => prevState.map((item) => {
      if (item.type === 'indication') {
        return { ...item, data: value };
      }
      return item;
    }));
  }

  const onClearClick = () => {
    setSelectedFilters({
      indication_name: [],
      question_type: [],
      overall_status: [],
      country: [],
      question_asked_by: [],
      question_date: undefined,
      response_by: [],
      response_date: undefined,
    });
    setSelectedDatesForQuestion('')
    setSelectedDatesForResponse('')

    const data = {
      indication_name: [],
      question_type: [],
      overall_status: [],
      country: [],
      question_asked_by: [],
      question_date: undefined,
      response_by: [],
      response_date: undefined,
    }
    setIsFilterPopoverVisible(false);
    dispatch(
      getQuestionAction({
        project_id: projectID,
        page: 1,
        size: 10,
        filters: JSON.stringify({ ...data }),
      }),
    );
  };

  const handleRangeChange = (dates) => {
    if (dates) {
      const startDate = moment(dates[0]).startOf('day').unix();
      let endDate;
      if (dates[1]) {
        endDate = moment(dates[1]).endOf('day').unix();
      } else {
        endDate = moment().endOf('day').unix();
      }
      setSelectedFilters({ ...selectedFilters, question_date: [startDate, endDate] });
      setSelectedDatesForQuestion(dates);
    } else {
      setSelectedFilters({ ...selectedFilters, question_date: undefined });
      setSelectedDatesForQuestion(null);
    }
  };

  const handleRangeChangeForResponse = (dates) => {
    if (dates) {
      const startDate = moment(dates[0]).startOf('day').unix();
      let endDate;

      if (dates[1]) {
        endDate = moment(dates[1]).endOf('day').unix();
      } else {
        endDate = moment().endOf('day').unix();
      }

      setSelectedFilters({ ...selectedFilters, response_date: [startDate, endDate] });
      setSelectedDatesForResponse(dates);
    } else {
      setSelectedFilters({ ...selectedFilters, response_date: undefined });
      setSelectedDatesForResponse(null);
    }
  };

  const handleCalendarClose = () => {
    if (selectedDatesForQuestion && !selectedDatesForQuestion[1]) {
      const startDateUnix = moment(selectedDatesForQuestion[0]).unix();
      const endDateUnix = moment().endOf('day').unix();
      setSelectedFilters({ ...selectedFilters, question_date: [startDateUnix, endDateUnix] });
      setSelectedDatesForQuestion([selectedDatesForQuestion[0], moment()]);
    }
  };

  const handleCalendarCloseForResponse = () => {
    if (selectedDatesForResponse && !selectedDatesForResponse[1]) {
      const startDateUnix = moment(selectedDatesForResponse[0]).unix();
      const endDateUnix = moment().endOf('day').unix();
      setSelectedFilters({ ...selectedFilters, response_date: [startDateUnix, endDateUnix] });
      setSelectedDatesForResponse([selectedDatesForResponse[0], moment()]);
    }
  };

  const onApplyClick = () => {
    setIsFilterPopoverVisible(false);
    dispatch(
      getQuestionAction({
        project_id: projectID,
        // project_id: projectID,
        page: 1,
        size: 10,
        filters: JSON.stringify({ ...selectedFilters }),
      }),
    );
  };

  const handleCountryChange = (value) => {
    const tempFilters = { ...selectedFilters };
    const key = 'country'
    tempFilters[key] = value;
    setSelectedFilters(tempFilters);
  }

  const renderFilterContent = () => {
    return (
      <div className="analysis-filter-popover">
        <div className="analysis-filter-section">
          <div className="analysis-filter-section-filter">
            <div className="analysis-filter-section-filter-select">
              <div className="filter-value-title">Indication Name</div>
              <AutoSuggest
                apiCall={onIndicationChangeInput}
                apiName="autosuggestdata"
                onChange={(val) => handleChange(val, 'indication_name')}
                selections={selectedFilters.indication_name}
                setValue={(val) => handleChange(val, 'indication_name')}
                cancelAction={getautosuggestionCancelAction}
                resetAction={getautosuggestionResetAction}
                key="indication"
                defaultValue={selectedFilters.indication_name}
              />
            </div>

            <div className="analysis-filter-section-filter-select">
              <div className="filter-value-title">Question Type</div>
              <Select
                onChange={(val) => handleChange(val, 'question_type')}
                value={selectedFilters.question_type}
              >
                <Option value="Project Specific"> Project Specific</Option>
                <Option value="Generic"> Generic</Option>
              </Select>
            </div>

            <div className="analysis-filter-section-filter-select">
              <div className="filter-value-title">Question Asked By</div>
              <Select
                showSearch
                placeholder="Select Name"
                className="input-wrapper"
                value={selectedFilters.question_asked_by}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                onChange={(e) => setSelectedFilters({
                  ...selectedFilters,
                  question_asked_by: [e],
                })}
              >
                {userList && userList?.map((itm) => {
                  return <Option value={itm.id}>{itm.name}</Option>
                })}
              </Select>
            </div>

            <div className="analysis-filter-section-filter-select">
              <div className="filter-value-title">Question Creation Date</div>
              {/* <DatePicker
                placeholder="Select Date"
                format="DD-MMM-YYYY"
                value={selectedFilters?.question_date ? moment(selectedFilters?.question_date * 1000) : undefined}
                // onChange={handleStartDateChange}
              /> */}
              <RangePicker
                value={selectedDatesForQuestion}
                onChange={handleRangeChange}
                onCalendarChange={handleRangeChange}
                onOpenChange={(open) => {
                  if (!open) {
                    handleCalendarClose();
                  }
                }}
                format="YYYY-MM-DD"
              />
            </div>

            <div className="analysis-filter-section-filter-select">
              <div className="filter-value-title">Country</div>
              <Select
                showSearch
                placeholder="Select country"
                mode="multiple"
                value={selectedFilters.country}
                onChange={(val) => handleCountryChange(val)}
              >
                {runAnalysisFilters?.flag && runAnalysisFilters?.data
                      && runAnalysisFilters?.data?.map((itm) => {
                        return <Option value={itm.key}>{itm.key}</Option>;
                      })}
              </Select>

            </div>

            <div className="analysis-filter-section-filter-select">
              <div className="filter-value-title">Response By</div>
              <Select
                showSearch
                placeholder="Select Name"
                className="input-wrapper"
                optionFilterProp="children"
                value={selectedFilters.response_by}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                onChange={(e) => setSelectedFilters({
                  ...selectedFilters,
                  response_by: [e],
                })}
              >
                {userList && userList?.map((itm) => {
                  return <Option value={itm.id}>{itm.name}</Option>
                })}
              </Select>
            </div>

            <div className="analysis-filter-section-filter-select">
              <div className="filter-value-title">Response Date</div>
              {/* <DatePicker
                placeholder="Select Date"
                format="DD-MMM-YYYY"
                // onChange={handleEndDateChange}
                      // onChange={(e) => { setQueryCriteria({ ...queryCriteria, end_date: e.unix() }) }}
                value={selectedFilters?.response_date ? moment(selectedFilters?.response_date * 1000) : undefined}
              /> */}
              <RangePicker
                value={selectedDatesForResponse}
                onChange={handleRangeChangeForResponse}
                onCalendarChange={handleRangeChangeForResponse}
                onOpenChange={(open) => {
                  if (!open) {
                    handleCalendarCloseForResponse();
                  }
                }}
                format="YYYY-MM-DD"
              />
            </div>

            <div className="analysis-filter-section-filter-select">
              <div className="filter-value-title">Status</div>
              <Select
                onChange={(val) => handleChange(val, 'overall_status')}
                value={selectedFilters.overall_status}
              >
                <Option value="Answered"> Answered</Option>
                <Option value="Not Answered"> Not Answered</Option>
              </Select>
            </div>
          </div>
        </div>
        <div className="filter-submit-btns">
          <Button className="clear-btn" onClick={() => onClearClick()}>
            Clear
          </Button>
          <Button
            className="apply-btn"
            type="primary"
            onClick={() => onApplyClick()}
            // disabled={
            //   selectedFilters?.indication_name?.length === 0
            //   && selectedFilters?.question_type?.length === 0
            //   && selectedFilters?.country?.length === 0
            //   && selectedFilters?.overall_status?.length === 0
            // }
          >
            Apply
          </Button>
        </div>
      </div>
    );
  };

  const addQuestionBtnClick = () => {
    setAddQuestionModal(true);
  };

  const handleDropdown = (idx, value) => {
    setFormState((s) => {
      return [...s.slice(0, idx), { type: value }, ...s.slice(idx + 1)];
    });
  };

  // const onChangeRadioBtn = (e, type, index) => {
  //   setFormStateForResponse((s) => {
  //     return [...s.slice(0, index), { type, data: e.target.value }, ...s.slice(index + 1)];
  //   });
  // };

  useEffect(() => {
    const indicationItem = formState.find((item) => item.type === 'indication');
    setIndicationForAdd(indicationItem ? indicationItem.value : null)
  }, [formState])

  useEffect(() => {
    const indicationItem = formStateForEdit.find((item) => item.type === 'indication');
    setIndicationForEdit(indicationItem ? indicationItem.data : null)
  }, [formStateForEdit])

  useEffect(() => {
    const indicationItem = formStateForCopy.find((item) => item.type === 'indication');
    setIndicationForCopy(indicationItem ? indicationItem.data : null)
  }, [formStateForCopy])

  const getData = (f, idx) => {
    switch (f.type) {
      case 'dosage':
      case 'moa':
      case 'roa':
      case 'line_of_therapy':
      case 'age_range':
      case 'drug':
        return (
          <div className="render-details">
            <TextType
              type={f.type}
              state={f}
              setState={setFormState}
              index={idx}
              fdata={filterValues}
            />
            {/* <div className="render-radio-btn">
              <Radio.Group onChange={(e) => onChangeRadioBtn(e, f.type, idx)}>
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </div> */}
          </div>
        );

      case 'indication':
        return (
          <div className="render-details">
            <AutoSuggest
              apiCall={onIndicationChangeInput}
              apiName="autosuggestdata"
              onChange={(val) => handleChangeForAdd(val)}
              selections={indicationForAdd}
              setValue={(val) => handleChangeForAdd(val)}
              cancelAction={getautosuggestionCancelAction}
              resetAction={getautosuggestionResetAction}
              key="indication"
              defaultValue={indicationForAdd}
            />
            {/* <div className="render-radio-btn">
              <Radio.Group onChange={(e) => onChangeRadioBtn(e, f.type, idx)} style={{ display: 'flex', paddingLeft: '10px' }}>
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </div> */}
          </div>
        )
      default:
        return <></>;
    }
  };

  // const handleDropdownForResponse = (idx, value) => {
  //   setFormStateForResponse((s) => {
  //     return [...s.slice(0, idx), { type: value }, ...s.slice(idx + 1)];
  //   });
  // };

  // const handleDropdownForResponseForEdit = (idx, value) => {
  //   setFormStateForResponseForEdit((s) => {
  //     return [...s.slice(0, idx), { type: value }, ...s.slice(idx + 1)];
  //   });
  // };

  // const onChangeRadioBtn = (e, type, index) => {
  //   setFormStateForResponse((s) => {
  //     return [...s.slice(0, index), { type, data: e.target.value }, ...s.slice(index + 1)];
  //   });
  // };

  // const onChangeRadioBtnForEdit = (e, type, index) => {
  //   setFormStateForEdit((s) => {
  //     return [...s.slice(0, index), { ...s[index], mandatory: e.target.value }, ...s.slice(index + 1)];
  //   });
  // };

  // const handleRemoveForResponse = (index) => {
  //   setFormStateForResponse((s) => {
  //     const oldState = [...s];
  //     oldState.splice(index, 1);
  //     return [...oldState];
  //   });
  // };

  // const handleAddForResponse = () => {
  //   setFormStateForResponse((s) => [...s, { type: '' }]);
  // };

  // const handleRemoveForResponseForEdit = (index) => {
  //   setFormStateForResponseForEdit((s) => {
  //     const oldState = [...s];
  //     oldState.splice(index, 1);
  //     return [...oldState];
  //   });
  // };

  // const handleAddForResponseForEdit = () => {
  //   setFormStateForResponseForEdit((s) => [...s, { type: '' }]);
  // };

  // const getDataForResponse = (f, idx) => {
  //   switch (f.type) {
  //     case 'indication':
  //     case 'dosage':
  //     case 'moa':
  //     case 'roa':
  //     case 'line_of_therapy':
  //     case 'age_range':
  //     case 'drug':
  //       return (
  //         <div className="radio-sec">
  //           <Radio.Group onChange={(e) => onChangeRadioBtn(e, f.type, idx)}>
  //             <Radio value="Yes">Yes</Radio>
  //             <Radio value="No">No</Radio>
  //           </Radio.Group>
  //         </div>
  //       );

  //     default:
  //       return <></>;
  //   }
  // };

  // const getDataForResponseForEdit = (f, idx) => {
  //   switch (f.type) {
  //     case 'indication':
  //     case 'dosage':
  //     case 'moa':
  //     case 'roa':
  //     case 'line_of_therapy':
  //     case 'age_range':
  //     case 'drug':
  //       return (
  //         <div className="radio-sec">
  //           <Radio.Group
  //             onChange={(e) => onChangeRadioBtnForEdit(e, f.type, idx)}
  //             value={f.data === 'yes' || f.data === 'Yes' ? 'Yes' : 'No'}
  //           >
  //             <Radio value="Yes">Yes</Radio>
  //             <Radio value="No">No</Radio>
  //           </Radio.Group>
  //         </div>
  //       );

  //     default:
  //       return <></>;
  //   }
  // };
  // const dropDataForResponse = (f, idx) => {
  //   return (
  //     <>
  //       <Select
  //         value={f.type}
  //         className="select-drodown-item"
  //         onChange={(e) => handleDropdownForResponse(idx, e)}
  //         style={{ width: 200 }}
  //       >
  //         {filterValues
  //           && filterValues.map((l, index) => {
  //             return (
  //               <Option
  //                 key={index}
  //                 value={l.key}
  //                 disabled={isDropDownDisabled(l.key, 'addResponse')}
  //               >
  //                 {l.value}
  //               </Option>
  //             );
  //           })}
  //       </Select>

  //       <div>{getDataForResponse(f, idx)}</div>
  //     </>
  //   );
  // };

  // const dropDataForResponseForEdit = (f, idx) => {
  //   return (
  //     <>
  //       <Select
  //         value={f.type}
  //         className="select-drodown-item"
  //         onChange={(e) => handleDropdownForResponseForEdit(idx, e)}
  //         style={{ width: 200 }}
  //       >
  //         {filterValues
  //           && filterValues.map((l, index) => {
  //             return (
  //               <Option
  //                 key={index}
  //                 value={l.key}
  //                 disabled={isDropDownDisabled(l.key, 'editQuestionResponse')}
  //               >
  //                 {l.value}
  //               </Option>
  //             );
  //           })}
  //       </Select>

  //       <div>{getDataForResponseForEdit(f, idx)}</div>
  //     </>
  //   );
  // };

  const handleAdd = () => {
    setFormState((s) => [...s, { type: '' }]);
  };

  const handleRemove = (index) => {
    if (formState.length > 1) {
      setFormState((s) => {
        const oldState = [...s];
        oldState.splice(index, 1);
        return [...oldState];
      });
    } else {
      setFormState([{ type: '' }])
    }
  };

  const isDropDownDisabled = (key, type) => {
    if (type === 'addQuestion') {
      return formState?.filter((a) => {
        return key === a.type
      }).length;
    }
    if (type === 'editQuestion') {
      return formStateForEdit?.filter((a) => {
        return key === a.type
      }).length;
    }
    // if (type === 'addResponse') {
    //   return formStateForEdit?.filter((a) => {
    //     return key === a.type
    //   }).length;
    // }
    // if (type === 'editQuestionResponse') {
    //   return formStateForResponseForEdit?.filter((a) => {
    //     return key === a.type
    //   }).length;
    // }
    return ''
  };

  const dropData = (f, idx) => {
    return (
      <>
        <Select
          value={f.type}
          className="select-drodown-item"
          onChange={(e) => handleDropdown(idx, e)}
          style={{ minWidth: '40%' }}
        >
          {filterValues
            && filterValues.map((l, index) => {
              return (
                <Option
                  key={index}
                  value={l.key}
                  disabled={isDropDownDisabled(l.key, 'addQuestion')}
                >
                  {l.value}
                </Option>
              );
            })}
        </Select>

        <div className="render-item">{getData(f, idx)}</div>
      </>
    );
  };

  // const renderAdditionalResponseDetails = () => {
  //   return (
  //     <div className="filter-wrapper">
  //       <div className="filter-wrapper-con">
  //         <div className="filter-wrapper-title">Details</div>
  //         <div className="filter-wrapper-field"> Mandatory?</div>
  //       </div>
  //       {formStateForResponse
  //         && formStateForResponse.map((f, idx) => {
  //           return (
  //             <div key={idx} className="newfilter">
  //               <div className="filter-sec" style={{ display: 'flex' }}>
  //                 {dropDataForResponse(f, idx)}
  //                 <>
  //                   {formStateForResponse.length > 1 && (
  //                     <Button
  //                       onClick={() => handleRemoveForResponse(idx)}
  //                       className="plus-btn minus-btn"
  //                     >
  //                       -
  //                     </Button>
  //                   )}
  //                   {idx === formStateForResponse.length - 1 && (
  //                     <Button onClick={handleAddForResponse} className="plus-btn">
  //                       +
  //                     </Button>
  //                   )}
  //                 </>
  //               </div>
  //             </div>
  //           );
  //         })}
  //     </div>
  //   );
  // };

  // const renderAdditionalResponseDetailsForEdit = () => {
  //   return (
  //     <div className="filter-wrapper">
  //       <div className="filter-wrapper-con">
  //         <div className="filter-wrapper-title">Details</div>
  //         <div className="filter-wrapper-field"> Mandatory?</div>
  //       </div>
  //       {formStateForResponseForEdit
  //         && formStateForResponseForEdit.map((f, idx) => {
  //           return (
  //             <div key={idx} className="newfilter">
  //               <div className="filter-sec" style={{ display: 'flex' }}>
  //                 {dropDataForResponseForEdit(f, idx)}
  //                 <>
  //                   {formStateForResponseForEdit.length > 1 && (
  //                     <Button
  //                       onClick={() => handleRemoveForResponseForEdit(idx)}
  //                       className="plus-btn minus-btn"
  //                     >
  //                       -
  //                     </Button>
  //                   )}
  //                   {idx === formStateForResponseForEdit.length - 1 && (
  //                     <Button onClick={handleAddForResponseForEdit} className="plus-btn">
  //                       +
  //                     </Button>
  //                   )}
  //                 </>
  //               </div>
  //             </div>
  //           );
  //         })}
  //     </div>
  //   );
  // };

  const onOptionChange = (e, idx) => {
    const list = [...optionValue];
    list[idx] = e.target.value;
    setOptionValue(list);
  };

  const onOptionChangeForEdit = (e, idx) => {
    const list = [...optionValueForEdit];
    list[idx] = e.target.value;
    setOptionValueForEdit(list);
  };

  const removeDefaultOption = (idx) => {
    const list = [...optionValue];
    list.splice(idx, 1);
    if (!list.length) {
      setOptionValue(['']);
    } else {
      setOptionValue(list);
    }
  };
  const removeDefaultOptionForEdit = (idx) => {
    const list = [...optionValueForEdit];
    list.splice(idx, 1);
    if (!list.length) {
      setOptionValueForEdit(['']);
    } else {
      setOptionValueForEdit(list);
    }
  };
  const addNewOption = () => {
    setOptionValue([...optionValue, '']);
  };

  const addNewOptionForEdit = () => {
    setOptionValueForEdit([...optionValueForEdit, '']);
  };

  const renderAdditionalDetails = () => {
    return (
      <div className="filter-wrapper">
        {/* <div className="mandatory-text">Is Mandatory?</div> */}
        {formState
          && formState.map((f, idx) => {
            return (
              <div key={idx} className="newfilter">
                <div
                  className="filter-sec"
                  style={{
                    display: 'flex',
                    margin: 10,
                  }}
                >
                  {dropData(f, idx)}
                  <>
                    {formState.length > 0 && (
                      <Button onClick={() => handleRemove(idx)} className="plus-btn minus-btn">
                        -
                      </Button>
                    )}
                    {idx === formState.length - 1 && (
                      <Button onClick={handleAdd} className="plus-btn">
                        +
                      </Button>
                    )}
                  </>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const renderAddQuestionList = () => {
    return (
      <div className="add-question-list">
        <div className="add-question-list-content">
          <div className="combine-border-view-list no-combine-border-view-list">
            {/* <div className="combine-border-view-list-title">Question Details</div> */}
            <div className="add-question-list-content-input border-view-list">
              <div className="add-question-list-content-input-title"> Details</div>
              <div className="add-question-list-content-input-data"> {renderAdditionalDetails()}</div>
            </div>
            <div className="combine-border-view-list-data">
              <div className="add-question-list-content-input">
                <div className="add-question-list-content-input-title"> Question Text
                  <span style={{ color: 'red' }}> *</span>
                </div>
                <div className="add-question-list-content-input-data">
                  <Input
                    onChange={(e) => setAddQuestionData({
                      ...addQuestionData,
                      question_text: e.target.value,
                    })}
                    value={addQuestionData.question_text}
                  />
                </div>
              </div>
              <div className="add-question-list-content-input">
                <div className="add-question-list-content-input-title"> Question Category
                  <span style={{ color: 'red' }}> *</span>
                </div>
                <div className="add-question-list-content-input-data">
                  <Select
                    onChange={(value) => setAddQuestionData({
                      ...addQuestionData,
                      question_category: value,
                    })}
                    value={addQuestionData.question_category}
                  >
                    <Option value="Project Specific"> Project Specific</Option>
                    <Option value="Generic"> Generic</Option>
                  </Select>
                </div>
              </div>
              <div className="add-question-list-content-input">
                <div className="add-question-list-content-input-title">Country
                  <span style={{ color: 'red' }}> *</span>
                </div>
                <div className="add-question-list-content-input-data">
                  <Select
                    showSearch
                    placeholder="Select country"
                    mode="multiple"
                    value={addQuestionData.country}
                    onChange={(val) => setAddQuestionData({
                      ...addQuestionData,
                      country: val,
                    })}
                  >
                    {runAnalysisFilters.flag && runAnalysisFilters?.data
                    && runAnalysisFilters?.data.map((itm) => {
                      return <Option value={itm.key}>{itm.key}</Option>;
                    })}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className="option-setion border-view-list">
            <div className="add-question-list-content-input-title">Response Options:
              <span style={{ color: 'red' }}> *</span>
            </div>
            <div className="add-question-list-content-input">
              <div className="add-question-list-content-input-data">
                <div className="add-question-list-content-input-data-select">
                  <div className="add-question-list-content-input-data-title">Type</div>
                  <Select onChange={(val) => setOptionChoice(val)} value={optionChoice}>
                    <Option value="Free Text"> Free Text</Option>
                    <Option value="Multiple Choice"> Multiple Choice</Option>
                    <Option value="Single Choice"> Single Choice</Option>
                  </Select>
                </div>
                {optionChoice === 'Multiple Choice' || optionChoice === 'Single Choice' ? (
                  <div className="genrated-inputs-content-choice">
                    <div className="genrated-inputs-content-choice-title">Choices: </div>
                    <div className="genrated-inputs-content-choice-data">
                      {optionValue.map((itm, childIndex) => {
                        return (
                          <div className="genrated-inputs-content-choice-value genrated-inputs-content">
                            <Input onChange={(e) => onOptionChange(e, childIndex)} value={itm} />
                            <Button
                              className="remove-btn-icon plus-btn"
                              title="remove"
                              onClick={() => removeDefaultOption(childIndex)}
                            >
                              -
                            </Button>
                            <div className="add-btn">
                              {optionValue.length - 1 === childIndex && (
                                <Button className="plus-btn" onClick={addNewOption}>
                                  +
                                </Button>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="add-question-list-content-input add-question-last-input border-view-list">
          <div className="add-question-list-content-input-title">Additional Details Requested</div>
          <div className="add-question-list-content-input-data">
            {renderAdditionalResponseDetails()}
          </div>
        </div> */}
      </div>
    );
  };

  const renderCopyQuestionContent = () => {
    return (
      <div className="add-question-list">
        <div className="add-question-list-content">
          <div className="combine-border-view-list no-combine-border-view-list">
            {/* <div className="combine-border-view-list-title">Question Details</div> */}
            <div className="combine-border-view-list-data">
              <div className="add-question-list-content-input border-view-list">
                <div className="add-question-list-content-input-title"> Details</div>
                <div className="add-question-list-content-input-data">
                  {' '}
                  {renderAdditionalDetailsForCopy()}
                </div>
              </div>
              <div className="add-question-list-content-input">
                <div className="add-question-list-content-input-title"> Question Text
                  <span style={{ color: 'red' }}> *</span>
                </div>
                <div className="add-question-list-content-input-data">
                  <Input
                    value={copyQuestion.question_text}
                    onChange={(e) => {
                      setCopyQuestion({
                        ...copyQuestion,
                        question_text: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="add-question-list-content-input">
                <div className="add-question-list-content-input-title"> Question Category
                  <span style={{ color: 'red' }}> *</span>
                </div>
                <div className="add-question-list-content-input-data">
                  <Select
                    value={copyQuestion.question_category}
                    onChange={(val) => {
                      setCopyQuestion({
                        ...copyQuestion,
                        question_category: val,
                      });
                    }}
                  >
                    <Option value="Project Specific"> Project Specific</Option>
                    <Option value="Generic"> Generic</Option>
                  </Select>
                  {/* <Input value={copyQuestion.question_category} disabled /> */}
                </div>
              </div>
              <div className="add-question-list-content-input">
                <div className="add-question-list-content-input-title">Country
                  <span style={{ color: 'red' }}> *</span>
                </div>
                <div className="add-question-list-content-input-data">
                  <Select
                    onChange={(val) => {
                      setCopyQuestion({
                        ...copyQuestion,
                        country: val,
                      });
                    }}
                    value={copyQuestion.country}
                    mode="multiple"
                  >
                    {runAnalysisFilters.flag && runAnalysisFilters?.data
                    && runAnalysisFilters?.data?.map((itm) => {
                      return <Option value={itm.key}>{itm.key}</Option>;
                    })}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className="option-setion border-view-list">
            <div className="add-question-list-content-input-title">Response Option:<span style={{ color: 'red' }}> *</span> </div>
            <div className="add-question-list-content-input">
              <div className="add-question-list-content-input-data">
                <div className="add-question-list-content-input-data-select">
                  <div className="add-question-list-content-input-data-title">Type</div>
                  <Select
                    onChange={(val) => {
                      setOptionChoiceForCopy(val);
                    }}
                    value={optionChoiceForCopy}
                    style={{ minWidth: 200 }}
                  >
                    <Option value="Free Text"> Free Text</Option>
                    <Option value="Multiple Choice"> Multiple Choice</Option>
                    <Option value="Single Choice"> Single Choice</Option>
                  </Select>
                </div>
                {optionChoiceForCopy === 'Multiple Choice'
              || optionChoiceForCopy === 'Single Choice' ? (
                <div className="genrated-inputs-content-choice">
                  <div className="genrated-inputs-content-choice-title">Choices: </div>
                  <div className="genrated-inputs-content-choice-data">
                    {optionValueForCopy.map((itm, childIndex) => {
                      return (
                        <div className="genrated-inputs-content-choice-value genrated-inputs-content">
                          <Input
                            onChange={(e) => onOptionChangeForCopy(e, childIndex)}
                            value={itm}
                          />
                          <Button
                            className="remove-btn-icon plus-btn"
                            title="remove"
                            onClick={() => removeDefaultOptionForCopy(childIndex)}
                          >
                            -
                          </Button>
                          <div className="add-btn">
                            {optionValueForCopy.length - 1 === childIndex && (
                              <Button className="plus-btn" onClick={addNewOptionForCopy}>
                                +
                              </Button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                  ) : (
                    ''
                  )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="add-question-list-content-input add-question-last-input border-view-list">
          <div className="add-question-list-content-input-title">Additional Details Requested</div>
          <div className="add-question-list-content-input-data">
            {renderAdditionalResponseDetailsForEdit()}
          </div>
        </div> */}
        <div className="response-question-section">
          <div className="footer-sec">
            <Button
              onClick={() => addCopyQuestion()}
              disabled={
                copyQuestion.question_category === ''
                || copyQuestion.question_text === ''
                || copyQuestion.country.length === 0
                || (optionChoiceForCopy === '') || ((optionChoiceForCopy === 'Multiple Choice' || optionChoiceForCopy === 'Single Choice') && optionValueForCopy[0] === '')
              }
            > Copy Question
            </Button>
          </div>
        </div>
      </div>
    );
  }

  const onOptionsChecked = (e, itm) => {
    if (e.target.checked) {
      setResponseOption((val) => [...val, itm]);
    } else {
      const obj = responseOption.filter((val) => val !== itm);
      setResponseOption(obj);
    }
  };

  const onRadioBtnChange = (val) => {
    setResponseOption(val);
  };

  // const onAdditionalRespChange = (e, itm) => {
  //   const newArray = responseTextCapture.map((item) => {
  //     if (item.key === itm.key) {
  //       return { ...item, value: e.target.value };
  //     }
  //     return item;
  //   });
  //   setResponseTextCapture(newArray);
  // };

  const handleRemoveForEdit = (idx) => {
    if (formStateForEdit.length > 1) {
      setFormStateForEdit((s) => {
        const oldState = [...s];
        oldState.splice(idx, 1);
        return [...oldState];
      });
    } else {
      setFormStateForEdit([{ type: '' }])
    }
  };

  const handleAddForEdit = () => {
    setFormStateForEdit((s) => [...s, { type: '' }]);
  };

  const handleAddForCopy = () => {
    setFormStateForCopy((s) => [...s, { type: '' }]);
  }

  const handleDropdownForEdit = (idx, value) => {
    setFormStateForEdit((s) => {
      return [...s.slice(0, idx), { type: value }, ...s.slice(idx + 1)];
    });
  };

  const onOptionsCheckedForEdit = (e, itm) => {
    if (e.target.checked) {
      setEditResponseOption((val) => [...val, itm]);
    } else {
      const obj = editResponseOption.filter((val) => val !== itm);
      setEditResponseOption(obj);
    }
  };

  const renderEditResponseModalContent = () => {
    console.log('editDataForResponse', editDataForResponse)
    return (
      <>
        <div className="response-question-section">
          <div className="response-question-section-group">
            <div className="response-question-section-content">
              <div className="response-question-section-content-title"> Question Text</div>
              <div className="response-question-section-content-data">
                {' '}
                {editDataForResponse.question_text}
              </div>
            </div>
            <div className="response-question-section-content">
              <div className="response-question-section-content-title"> Question Category</div>
              <div className="response-question-section-content-data">
                {' '}
                {editDataForResponse.question_category}
              </div>
            </div>
            <div className="response-question-section-content">
              <div className="response-question-section-content-title"> Country</div>
              <div className="response-question-section-content-data">
                {' '}
                {editDataForResponse.country.join(', ')}
              </div>
            </div>
            {/* <div className="response-question-section-content">
              <div className="response-question-section-content-title">
                {' '}
                Choose the country you have to answer:
                <span style={{ color: 'red' }}> *</span>
              </div>
              <div className="response-question-section-content-data select-option-view">
                <Input value={selectedCountryForResponse} style={{ width: '260px' }} /> */}
            {/* </div> */}
            {/* </div> */}
            <div className="response-question-section-content-title resp-text"> Details </div>
            <div className="response-question-section-content">
              <div className="response-question-section-content-data select-option-view">
                {' '}
                {Object.keys(editDataForResponse.additonal_details).length !== 0 ? (Object.keys(editDataForResponse.additonal_details).map((key, index) => (
                  <div className="respon-key" key={index}>
                    <div className="respon-key-title"> {renderLabel(key)}</div>
                    <div className="respon-key-data">
                      {editDataForResponse.additonal_details[key]}
                    </div>
                  </div>
                ))) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingBottom: '20px',
                    }}
                  > No data available
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="response-question-section-group addi-req-section response-option">
            {editDataForResponse.options.choices.length === 0
            && editDataForResponse.options.type === '' ? (
              <div className="option-section">
                <div className="option-title"> Response Option: </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingBottom: '20px',
                  }}
                >
                  {' '}
                  No data available
                </div>
              </div>
              ) : (
                <div className="option-section">
                  <div className="option-title"> Response Option: </div>
                  <div className="response-question-section-content">
                    <div className="response-question-section-content-title option-type"> Type: </div>
                    <div className="response-question-section-content-data">
                      {' '}
                      {editDataForResponse.options.type}
                    </div>
                  </div>
                  {editDataForResponse.options.type === 'Multiple Choice' ? (
                    <div className="option-content">
                      <div className="option-content-title"> Choices : </div>{' '}
                      <div className="option-content-data">
                        {editDataForResponse.options.choices.map((itm) => {
                          return (
                            <div className="respon-key">
                              {console.log('editResponseOption----', editResponseOption)}
                              <div className="respon-key-data">
                                <Checkbox onChange={(e) => onOptionsCheckedForEdit(e, itm)} checked={editResponseOption.includes(itm)} />
                              </div>
                              <div className="respon-key-title"> {itm}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : editDataForResponse.options.type === 'Single Choice' ? (
                    <div className="option-content">
                      <div className="option-content-title"> Choices : </div>{' '}
                      <div className="option-content-data">
                        {editDataForResponse.options.choices.map((itm) => {
                          return (
                            <div className="respon-key">
                              <div className="respon-key-data">
                                <Radio.Group
                                  onChange={() => setEditResponseOption(itm)}
                                  value={editResponseOption}
                                >
                                  <Radio value={itm} />
                                </Radio.Group>
                              </div>
                              <div className="respon-key-title"> {itm}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    <div className="respon-key-free-text">
                      <div className="respon-key-choice"> Choice: </div>
                      <Input
                        onChange={(e) => setEditResponseOption(e.target.value)}
                        value={editResponseOption}
                      />
                    </div>
                  )}
                </div>
              )}
            {/* <div className="response-question-section-content addi-req-section">
              <div className="response-question-section-content-title"> Details</div>
              {console.log(
                'showDataForResponse.combinedResponse',
                showDataForResponse.combinedResponse,
              )}
              {showDataForResponse.combinedResponse.length !== 0 ? (
                <div className="response-question-section-content-data">
                  <div className="filter-wrapper-con">
                    <div className="filter-wrapper-title">Details of Fields</div>
                    <div className="filter-wrapper-title" />
                    <div className="filter-wrapper-field"> Mandatory?</div>
                    <div className="filter-wrapper-response"> Response</div>
                  </div>{' '}
                  {showDataForResponse.combinedResponse.map((itm) => {
                    return (
                      <div className="addtional-info-sec">
                        <div className="addtional-info-sec-com">
                          <div className="addtional-info-sec-key">{renderLabel(itm.key)} </div>
                          <div className="addtional-info-sec-key">{itm.detailsValue} </div>
                          <div className="addtional-info-se-mand"> {itm.mandatory} </div>
                        </div>
                        <div className="addtional-info-sec-input">
                          {' '}
                          <Input onChange={(e) => onAdditionalRespChange(e, itm)} />{' '}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingBottom: '20px',
                  }}
                >
                  No data available
                </div>
              )}
            </div> */}
            <div className="response-question-section-content comment-section-response">
              <div className="response-question-section-content-title"> Comments</div>
              <div className="response-question-section-content-data">
                {' '}
                <Input
                  onChange={(e) => setEditShowDataForResponse({
                    ...editDataForResponse,
                    comments: e.target.value,
                  })}
                  value={editDataForResponse.comments}
                />
              </div>
            </div>
          </div>
          <div className="footer-sec">
            <Button onClick={() => onUpdateResponse()}>
              Edit Response
            </Button>
          </div>
        </div>
      </>
    );
  };

  const getDataForEdit = (f, idx) => {
    switch (f.type) {
      case 'dosage':
      case 'moa':
      case 'roa':
      case 'line_of_therapy':
      case 'age_range':
      case 'drug':
        return (
          <div className="render-details">
            <TextType
              type={f.type}
              state={f}
              setState={setFormStateForEdit}
              index={idx}
              fdata={filterValues}
              flag="LCOR-Question"
            />
            {/* <div className="render-radio-btn">
              <Radio.Group
                onChange={(e) => onChangeRadioBtnForEdit(e, f.type, idx)}
                value={f.mandatory === 'yes' || f.mandatory === 'Yes' ? 'Yes' : f.mandatory === 'No' || f.mandatory === 'no' ? 'No' : undefined}
              >
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </div> */}
          </div>
        );

      case 'indication':
        return (
          <div className="render-details">
            <AutoSuggest
              apiCall={onIndicationChangeInput}
              apiName="autosuggestdata"
              onChange={(val) => handleChangeForEdit(val)}
              selections={indicationForEdit}
              setValue={(val) => handleChangeForEdit(val)}
              cancelAction={getautosuggestionCancelAction}
              resetAction={getautosuggestionResetAction}
              key="indication"
              defaultValue={indicationForEdit}
            />
            {/* <div className="render-radio-btn">
              <Radio.Group
                onChange={(e) => onChangeRadioBtnForEdit(e, f.type, idx)}
                value={f.mandatory === 'yes' || f.mandatory === 'Yes' ? 'Yes' : f.mandatory === 'No' || f.mandatory === 'no' ? 'No' : undefined}
              >
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
              </Radio.Group>
            </div> */}

          </div>
        // <AutoSuggestTypeForIndication
        //   type={f.type}
        //   state={f}
        //   setState={setFormState}
        //   index={idx}
        //   fdata={filterValues}
        //   apiCall={onIndicationChangeInput}
        //   apiName="autosuggestdata"
        //   setValue=""
        //   cancelAction={getautosuggestionCancelAction}
        //   resetAction={getautosuggestionResetAction}
        //   key="indication"
        // />
        )

      default:
        return <></>;
    }
  };

  const dropDataForEdit = (f, idx) => {
    return (
      <>
        <Select
          value={f.type}
          className="select-drodown-item"
          onChange={(e) => handleDropdownForEdit(idx, e)}
          style={{ minWidth: '40%' }}
        >
          {filterValues
            && filterValues.map((l, index) => {
              return (
                <Option
                  key={index}
                  value={l.key}
                  disabled={isDropDownDisabled(l.key, 'editQuestion')}
                >
                  {l.value}
                </Option>
              );
            })}
        </Select>

        <div>{getDataForEdit(f, idx)}</div>
      </>
    );
  };

  const renderAdditionalDetailsForEdit = () => {
    return (
      <div className="filter-wrapper">
        {/* <div className="mandatory-text">Is Mandatory?</div> */}
        {formStateForEdit
          && formStateForEdit.map((f, idx) => {
            return (
              <div key={idx} className="newfilter">
                <div
                  className="filter-sec"
                  style={{
                    display: 'flex',
                    margin: 10,
                  }}
                >
                  {dropDataForEdit(f, idx)}
                  <>
                    {formStateForEdit.length > 0 && (
                      <Button
                        onClick={() => handleRemoveForEdit(idx)}
                        className="plus-btn minus-btn"
                      >
                        -
                      </Button>
                    )}
                    {idx === formStateForEdit.length - 1 && (
                      <Button onClick={handleAddForEdit} className="plus-btn">
                        +
                      </Button>
                    )}
                  </>
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const exportNetworksClicked = () => {
    const filename = 'question_list_records.xlsx'
    const params = {
      project_id: projectID,
      is_downloading: true,
      filters: JSON.stringify({ ...selectedFilters }),
    }
    Axios({
      url: `${process.env.apiUrl}v0/projects/getQuestions?${stringify(params)}`,
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        Authorization: getCookie('accessToken'),
        'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click();
        return null;
      })
      .catch((e) => {
        return e;
      })
  }

  const isDisabled = () => {
    const data = responseTextCapture.filter((itm) => itm.mandatory === 'Yes')
    if (data.length) {
      const h = data.map((itm) => (itm.mandatory === 'Yes' && itm.value !== ''))
      if (h.includes(false)) {
        return true
      }
      return false
    }
    return false
  }

  const checkOption = () => {
    if (showDataForResponse.options.type === 'Single Choice' || showDataForResponse.options.type === 'Multiple Choice') {
      if (responseOption.length === 0) {
        return true
      }
      return false
    }
    if (showDataForResponse.options.type === 'Free Text') {
      if (responseOption === '') {
        return true
      }
      return false
    }
    return false
  }

  const renderResponseModalContent = () => {
    return (
      <>
        <div className="response-question-section">
          <div className="response-question-section-group">
            <div className="response-question-section-content">
              <div className="response-question-section-content-title"> Question Text</div>
              <div className="response-question-section-content-data">
                {' '}
                {showDataForResponse.question_text}
              </div>
            </div>
            <div className="response-question-section-content">
              <div className="response-question-section-content-title"> Question Category</div>
              <div className="response-question-section-content-data">
                {' '}
                {showDataForResponse.question_category}
              </div>
            </div>
            <div className="response-question-section-content">
              <div className="response-question-section-content-title"> Country</div>
              <div className="response-question-section-content-data">
                {' '}
                {showDataForResponse.country.join(', ')}
              </div>
            </div>
            <div className="response-question-section-content">
              <div className="response-question-section-content-title">
                {' '}
                Choose the country you have to answer:
                <span style={{ color: 'red' }}> *</span>
              </div>
              <div className="response-question-section-content-data select-option-view">
                <Select
                  value={selectedCountryForResponse}
                  className="select-drodown-item"
                  onChange={(e) => setSelectedCountryForResponse(e)}
                  style={{ width: 200 }}
                >
                  {showDataForResponse.country
                    && showDataForResponse.country.map((l, index) => {
                      return (
                        <Option
                          key={index}
                          value={l}
                          disabled={showDataForResponse.status[l] === 'Answered'}
                        >
                          {l}
                        </Option>
                      );
                    })}
                </Select>
              </div>
            </div>
            <div className="response-question-section-content-title resp-text"> Details </div>
            <div className="response-question-section-content">
              <div className="response-question-section-content-data select-option-view">
                {' '}
                {Object.keys(showDataForResponse.additonal_details).length !== 0 ? (Object.keys(showDataForResponse.additonal_details).map((key, index) => (
                  <div className="respon-key" key={index}>
                    <div className="respon-key-title"> {renderLabel(key)}</div>
                    <div className="respon-key-data">
                      {showDataForResponse.additonal_details[key]}
                    </div>
                  </div>
                ))) : (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingBottom: '20px',
                  }}
                  > No data available
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="response-question-section-group addi-req-section response-option">
            {showDataForResponse.options.choices.length === 0 && showDataForResponse.options.type === '' ? (
              <div className="option-section">
                <div className="option-title"> Response Option: </div>
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingBottom: '20px',
                }}
                > No data available
                </div>
              </div>

            ) : (
              <div className="option-section">
                <div className="option-title"> Response Option: </div>
                <div className="response-question-section-content">
                  <div className="response-question-section-content-title option-type"> Type: </div>
                  <div className="response-question-section-content-data">
                    {' '}
                    {showDataForResponse.options.type}
                  </div>
                </div>
                {showDataForResponse.options.type === 'Multiple Choice' ? (
                  <div className="option-content">
                    <div className="option-content-title"> Choices : </div>
                    {' '}
                    <div className="option-content-data">
                      {showDataForResponse.options.choices.map((itm) => {
                        return (
                          <div className="respon-key">
                            <div className="respon-key-data">
                              <Checkbox onChange={(e) => onOptionsChecked(e, itm)} />
                            </div>
                            <div className="respon-key-title"> {itm}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : showDataForResponse.options.type === 'Single Choice' ? (
                  <div className="option-content">
                    <div className="option-content-title"> Choices : </div>
                    {' '}
                    <div className="option-content-data">
                      {showDataForResponse.options.choices.map((itm) => {
                        return (
                          <div className="respon-key">
                            <div className="respon-key-data">
                              <Radio.Group onChange={() => onRadioBtnChange(itm)} value={responseOption}>
                                <Radio value={itm} />
                              </Radio.Group>
                            </div>
                            <div className="respon-key-title"> {itm}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <div className="respon-key-free-text">
                    <div className="respon-key-choice"> Choice: </div>
                    <Input
                      onChange={(e) => setResponseOption(e.target.value)}
                      value={responseOption}
                    />
                  </div>
                )}
              </div>

            )}
            {/* <div className="response-question-section-content addi-req-section">
              <div className="response-question-section-content-title">
                {' '}
                Details
              </div>
              {
                showDataForResponse.combinedResponse.length !== 0 ? (
                  <div className="response-question-section-content-data">
                    <div className="filter-wrapper-con">
                      <div className="filter-wrapper-title">Details of Fields</div>
                      <div className="filter-wrapper-title" />
                      <div className="filter-wrapper-field"> Mandatory?</div>
                      <div className="filter-wrapper-response"> Response</div>
                    </div>
                    {' '}
                    {showDataForResponse.combinedResponse.map((itm) => {
                      return (
                        <div className="addtional-info-sec">
                          <div className="addtional-info-sec-com">
                            <div className="addtional-info-sec-key">{renderLabel(itm.key)} </div>
                            <div className="addtional-info-sec-key">{itm.detailsValue} </div>
                            <div className="addtional-info-se-mand"> {itm.mandatory} </div>
                          </div>
                          <div className="addtional-info-sec-input">
                            {' '}
                            <Input onChange={(e) => onAdditionalRespChange(e, itm)} />{' '}
                          </div>
                        </div>
                      );
                    })}
                  </div>

                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingBottom: '20px',
                    }}
                  >
                    No data available

                  </div>
                )
              }

            </div> */}
            <div className="response-question-section-content comment-section-response">
              <div className="response-question-section-content-title"> Comments</div>
              <div className="response-question-section-content-data">
                {' '}
                <Input onChange={(e) => setComments(e.target.value)} value={comments} />
              </div>
            </div>
          </div>
          <div className="footer-sec">
            <Button
              onClick={() => onAddResponseApi()}
              disabled={isDisabled() || checkOption() || selectedCountryForResponse === ''}
            > Add Response
            </Button>
          </div>
        </div>
      </>
    );
  };

  const renderEditQuestionContent = () => {
    return (
      <div className="add-question-list">
        <div className="add-question-list-content">
          <div className="combine-border-view-list no-combine-border-view-list">
            {/* <div className="combine-border-view-list-title">Question Details</div> */}
            <div className="add-question-list-content-input border-view-list">
              <div className="add-question-list-content-input-title"> Details</div>
              <div className="add-question-list-content-input-data">
                {' '}
                {renderAdditionalDetailsForEdit()}
              </div>
            </div>
            <div className="combine-border-view-list-data">
              <div className="add-question-list-content-input">
                <div className="add-question-list-content-input-title"> Question Text
                  <span style={{ color: 'red' }}> *</span>
                </div>
                <div className="add-question-list-content-input-data">
                  <Input
                    value={addQuestionDataForEdit.question_text}
                    onChange={(e) => {
                      setAddQuestionDataForEdit({
                        ...addQuestionDataForEdit,
                        question_text: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="add-question-list-content-input">
                <div className="add-question-list-content-input-title"> Question Category
                  <span style={{ color: 'red' }}> *</span>
                </div>
                <div className="add-question-list-content-input-data">
                  <Select
                    value={addQuestionDataForEdit.question_category}
                    onChange={(val) => {
                      setAddQuestionDataForEdit({
                        ...addQuestionDataForEdit,
                        question_category: val,
                      });
                    }}
                  >
                    <Option value="Project Specific"> Project Specific</Option>
                    <Option value="Generic"> Generic</Option>
                  </Select>
                </div>
              </div>
              <div className="add-question-list-content-input">
                <div className="add-question-list-content-input-title">Country
                  <span style={{ color: 'red' }}> *</span>
                </div>
                <div className="add-question-list-content-input-data">
                  <Select
                    onChange={(val) => {
                      setAddQuestionDataForEdit({
                        ...addQuestionDataForEdit,
                        country: val,
                      });
                    }}
                    value={addQuestionDataForEdit.country}
                    mode="multiple"
                  >
                    {runAnalysisFilters.flag && runAnalysisFilters?.data
                    && runAnalysisFilters?.data.map((itm) => {
                      return <Option value={itm.key}>{itm.key}</Option>;
                    })}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <div className="option-setion border-view-list">
            <div className="add-question-list-content-input-title">Response Option:
              <span style={{ color: 'red' }}> *</span>
            </div>
            <div className="add-question-list-content-input">
              <div className="add-question-list-content-input-data">
                <div className="add-question-list-content-input-data-select">
                  <div className="add-question-list-content-input-data-title">Type</div>
                  <Select
                    onChange={(val) => {
                      setOptionChoiceForEdit(val);
                    }}
                    value={optionChoiceForEdit}
                    style={{ minWidth: 200 }}
                  >
                    <Option value="Free Text"> Free Text</Option>
                    <Option value="Multiple Choice"> Multiple Choice</Option>
                    <Option value="Single Choice"> Single Choice</Option>
                  </Select>
                </div>
                {optionChoiceForEdit === 'Multiple Choice'
              || optionChoiceForEdit === 'Single Choice' ? (
                <div className="genrated-inputs-content-choice">
                  <div className="genrated-inputs-content-choice-title">Choices: </div>
                  <div className="genrated-inputs-content-choice-data">
                    {optionValueForEdit.map((itm, childIndex) => {
                      return (
                        <div className="genrated-inputs-content-choice-value genrated-inputs-content">
                          <Input
                            onChange={(e) => onOptionChangeForEdit(e, childIndex)}
                            value={itm}
                          />
                          <Button
                            className="remove-btn-icon plus-btn"
                            title="remove"
                            onClick={() => removeDefaultOptionForEdit(childIndex)}
                          >
                            -
                          </Button>
                          <div className="add-btn">
                            {optionValueForEdit.length - 1 === childIndex && (
                              <Button className="plus-btn" onClick={addNewOptionForEdit}>
                                +
                              </Button>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                  ) : (
                    ''
                  )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="add-question-list-content-input add-question-last-input border-view-list">
          <div className="add-question-list-content-input-title">Additional Details Requested</div>
          <div className="add-question-list-content-input-data">
            {renderAdditionalResponseDetailsForEdit()}
          </div>
        </div> */}
        <div className="response-question-section">
          <div className="footer-sec">
            <Button
              onClick={() => setUpdateQuestionConfirmationModal(true)}
              disabled={
                addQuestionDataForEdit.question_category === ''
                || addQuestionDataForEdit.question_text === ''
                || addQuestionDataForEdit.country.length === 0
                || (optionChoiceForEdit === '') || ((optionChoiceForEdit === 'Multiple Choice' || optionChoiceForEdit === 'Single Choice') && optionValueForEdit[0] === '')
              }
            > Update
            </Button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Modal
        open={questionModal}
        onCancel={() => {
          setQuestionModal(false);
          setViewQuestionScreen(true)
        }}
        className="question-modal"
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Loader loading={getQuestionResponse.loading}>
          <div className="country-profile-question">
            <div className="question-modal-content">
              <div className="question-modal-content-header">
                <div className="question-modal-content-header-left">
                  <div className="back-btn-header">
                    <Button
                      className="back-btn"
                      onClick={() => {
                        setQuestionModal(false)
                        setViewQuestionScreen(true)
                      }}
                    >
                      {' '}
                      Back
                    </Button>
                  </div>
                  <div className="question-modal-content-view-title">
                    {' '}
                    Question and Answer History
                  </div>
                </div>
                <div className="question-modal-content-header-right">
                  <div className="export-to-csv-btn export-to-csv-network-btn">
                    <Button
                      className="export-to-csv-btn-icon export-networks-button"
                      title="Export"
                      onClick={() => exportNetworksClicked()}
                    />
                  </div>
                  <Popover
                    overlayClassName="filter-runanalysis-popover filter-count-popover"
                    placement="left"
                    title="Filter by:"
                    content={renderFilterContent()}
                    trigger="click"
                    open={isFilterPopoverVisible}
                    onOpenChange={(visibled) => {
                      setIsFilterPopoverVisible(visibled);
                    }}
                  >
                    <div className="filter-icon">
                      <Tooltip title="Filters">
                        <div className="filter-icon-img" />
                      </Tooltip>
                    </div>
                  </Popover>
                </div>
              </div>
              <div className="question-modal-content-view">
                <div className="question-modal-content-view-table">
                  {/* {renderHeader()} */}
                  <Loader loading={getQuestionResponse.loading}>
                    <div className="table-body-section">
                      <LazyLoadDiv
                        className="card-list scrollbar"
                        id="analysis-list"
                        total={getQuestionResponse.data.total}
                        currentTotal={(getQuestionResponse.data.data || []).length}
                        loadMoreRows={() => loadMoreRowsForList()}
                        height="calc(100vh - 320px)"
                      >
                        {questionList.length ? (
                          renderListOfQuestion()
                        ) : (
                          <div className="no-data-found-msg-country-question"> No data available</div>
                        )}
                      </LazyLoadDiv>
                    </div>
                  </Loader>
                  <div className="footer-button-wrapper footer-button-wrapper-pd save-analysis add-overlay-to-me add-no-overlay-to-me">
                    <div className="plus-add-btn">
                      <button
                        onClick={() => addQuestionBtnClick()}
                        type="button"
                        className="plus-btn"
                      >
                        <Tooltip title="Add Question">
                          <span className="title-plus">+</span>
                        </Tooltip>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Loader>
      </Modal>

      {addResponseModal && (
        <Modal
          open={addResponseModal}
          onCancel={() => {
            setAddResponseModal(false);
            setComments('');
            setSelectedCountryForResponse('');
            setResponseOption('');
          }}
          className="add-response-modal"
          title="Add Response"
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
          maskClosable={false}
        >
          {renderResponseModalContent()}
        </Modal>
      )}

      {updateQuestionConfirmationModal && (
        <Modal
          open={updateQuestionConfirmationModal}
          onCancel={() => setUpdateQuestionConfirmationModal(false)}
          onOk={() => onUpdateApiCall()}
          okText="Yes"
          cancelText="No"
          className="update-conf-modal"
        >
          <Loader loading={updateQuestionsResponse.loading}>
            <div className="update-conf-modal-title">
              {' '}
              Do you really want to update this question?
            </div>
          </Loader>
        </Modal>
      )}
      {deleteQuestionConfirmationModal && (
        <Modal
          open={deleteQuestionConfirmationModal}
          onCancel={() => setDeleteQuestionConfirmationModal(false)}
          onOk={() => onDeleteApiCall()}
          okText="Yes"
          cancelText="No"
          className="update-conf-modal"
        >
          <div className="update-conf-modal-title">
            {' '}
            Do you really want to delete this question? Once deleted, the question will NOT be
            available anywhere in LandSCAPE.
          </div>
        </Modal>
      )}
      {addQuestionModal && (
        <Modal
          title="Add Question"
          open={addQuestionModal}
          onCancel={() => {
            setAddQuestionModal(false);
            setFormState([{ type: '' }]);
            setFormStateForResponse([{ type: '' }]);
            setAddQuestionData({
              question_text: '',
              question_category: '',
              country: [],
            });
            setOptionChoice('');
            setOptionValue(['']);
          }}
          footer={null}
          closable={false}
          className="update-conf-modal"
          maskClosable={false}
        >
          <Loader loading={addQuestionsResponse.loading}>{renderAddQuestionList()}
            <div className="footer-button-wrapper footer-button-wrapper-pd">
              <Button
                className="cancel-wrapper cancel-btn ant-btn-default"
                onClick={() => {
                  setAddQuestionModal(false);
                  setFormState([{ type: '' }]);
                  setFormStateForResponse([{ type: '' }]);
                  setAddQuestionData({
                    question_text: '',
                    question_category: '',
                    country: [],
                  });
                  setOptionChoice('');
                  setOptionValue(['']);
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className="save-wrapper primary-btn ant-btn-primary"
                onClick={() => onAddApi()}
                disabled={
                  addQuestionData.question_category.length === 0
                  || addQuestionData.question_text === ''
                  || addQuestionData.country.length === 0
                  || (optionChoice === '') || ((optionChoice === 'Multiple Choice' || optionChoice === 'Single Choice') && optionValue[0] === '')
                }
              >
                Add
              </Button>
            </div>
          </Loader>
        </Modal>
      )}

      {editQuestionModal && (
        <Modal
          title="Edit Question"
          className="update-conf-modal"
          open={editQuestionModal}
          onCancel={() => setEditQuestionModal(false)}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
          maskClosable={false}
        >
          {renderEditQuestionContent()}
        </Modal>
      )}

      {CopyQuestionModal && (
      <Modal
        title="Copy Question"
        className="update-conf-modal"
        open={CopyQuestionModal}
        onCancel={() => setCopyQuestionModal(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        maskClosable={false}
      >
        {renderCopyQuestionContent()}
      </Modal>
      )}

      {
        editResponseModal && (
          <Modal
            open={editResponseModal}
            onCancel={() => {
              setEditResponseModal(false)
            // setAddResponseModal(false);
            // setComments('');
            // setSelectedCountryForResponse(selectedCountry);
            // setResponseOption('');
            }}
            className="add-response-modal"
            title="Edit Response"
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            maskClosable={false}
          >
            {renderEditResponseModalContent()}
          </Modal>
        )
      }

      {deleteResponseConfirmationModal && (
      <Modal
        open={deleteResponseConfirmationModal}
        onCancel={() => setDeleteResponseConfirmationModal(false)}
        onOk={() => onDeleteResponseCall()}
        okText="Yes"
        cancelText="No"
        className="update-conf-modal"
      >
        <div className="update-conf-modal-title">
          {' '}
          Do you really want to delete this Response?
        </div>
      </Modal>
      )}
    </div>
  );
};

export default CountryQuestionModal;
